import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import Footer from "../layouts/Footer";
import Header from "../layouts/Header";
import { Container } from "@mui/material";
import SevaBookingSuccessfulImage from "../assets/images/check_out/mycart/seva-booking-successful-image.svg";
import "../styles/pages/home.css";
import "../styles/pages/payment_cancel.css";
import Typography from "@mui/material/Typography";
import Special_offer from "../components/Special_offer";
import { useAppContext } from "../components/Auth";
import whatsapp from '../assets/images/icons/whatsapp.png'
import GTM from "../utils/gtm";
function ThankYou_Seva() {
   const { thankYouseva } = useAppContext();

   const location = useLocation();
   const navigate = useNavigate();

  //  useEffect(() => {
  //    if (thankYouseva !== true || thankYouseva === null) {
  //      navigate("/");
  //    }
  //  }, [thankYouseva]);

  //  if (thankYouseva !== true || thankYouseva === null) {
  //    navigate("/");
  //  }

  //  const transactionId = location.state?.transaction_id
  //    ? location.state.transaction_id
  //    : null;
  const transactionId = localStorage.getItem("transactionId");
   const amount = localStorage.getItem("payment_amount_seva");
   
   useEffect(() => {
    // Assuming gtag is already available globally
    window.gtag("event", "conversion", {
      send_to: "AW-16473080225/WFikCN3GmKcZEKGD_a49",
      value: amount,
      currency: "INR",
      transaction_id: transactionId,
      event_callback: () => {
        console.log("Conversion event tracked successfully!");
      },
    });
  }, [transactionId]);

   useEffect(() => {
    if (transactionId && amount) {
      const userName = localStorage.getItem("user_name");
      const slug = localStorage.getItem("slug");
      const gtm = new GTM();
      gtm.trackThankYou({
        transactionId,
        currency: "INR",
        amount,
      });
      gtm.trackPaymentSuccess(
        {
          transactionId,
          currency: "INR",
          amount,
          userName,
          slug,
        },
        "PujaPaymentSuccess"
      )
      console.log(amount,"testamt123")
    }
  }, [transactionId, amount]);
  //  useEffect(() => {
  //    // Assuming gtag is already available globally
  //    window.gtag("event", "conversion", {
  //      send_to: "AW-16473080225/WFikCN3GmKcZEKGD_a49",
  //      value: amount,
  //      currency: "INR",
  //      transaction_id: transactionId,
  //      event_callback: () => {
  //        console.log("Conversion event tracked successfully!");
  //      },
  //    });
  //  }, [transactionId]);

  //  console.log(thankYouseva);
  return (
    <>
      <Header />

      <div className="main_payment_cancel">
        <Container>
          <div className="payment_cancel">
            <div className="Seva-Booking">
              <img
                src={SevaBookingSuccessfulImage}
                alt="Seva Booking Successful"
              />
              <div className="Seva-Booking-text">
                <p>
                  <span>₹ {amount}</span>
                </p>
                <Typography variant="h5">Seva Booking Successful</Typography>
                <Typography variant="body1">
                  We have successfully received your details
                </Typography>
              </div>
              
                {/* You will get a confirmation call or WhatsApp within 12 hrs of
                booking */}
             
            </div>
          </div>
          <section className="our-special-offerss">
            <div className="our-special-offers-bg">
              <Special_offer />
            </div>
          </section>
        </Container>
      </div>

      <Footer />
      <a href="https://api.whatsapp.com/send?phone=919256585656" target="_blank"  class="btn-whatsapp-pulse btn-whatsapp-pulse-border">
          <img alt="whatsapp" src={whatsapp}/>
      </a>
    </>
  );
}

export default ThankYou_Seva;
