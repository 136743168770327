import { Button, Container } from "@mui/material";
import React from "react";
import "../styles/layouts/footer.css";
import Facebook from "../assets/images/icons/facebook.png";
import Instagram from "../assets/images/icons/instagram.png";
import Linkedin from "../assets/images/icons/linkedin.png";
import Youtube from "../assets/images/icons/youtube.png";
import Grid from "@mui/material/Grid";
import Download from "../assets/images/icons/download-file.svg";
import Footer_bg from "../assets/images/icons/footer-bg.png";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import logo from "../assets/images/logo/logo.svg";
import { Link } from "react-router-dom";
import email from '../assets/images/icons/email.png';
import phone from '../assets/images/icons/phone.png'
import timings from '../assets/images/icons/timings.png'
import small_support_sm_right from '../assets/images/dharma-parirakshana/right-sm.svg'


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "4px",
};
function Footer() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <Modal
        className="login_modal"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        disablePortal
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        sx={{
          zIndex:1200,
        }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <section className="great-things">
              <div className="great-things-content">
                <h2>Great things coming soon</h2>
                <p>
                  Big ideas drive our tiny but rapidly expanding company. Await
                  updates!
                </p>
                <div class="banner-buttons">
                  <Button
                    onClick={() => handleClose()}
                    disableRipple
                    disableElevation
                    className="banner-btn-1"
                  >
                    Close
                  </Button>
                </div>
              </div>
            </section>
          </Box>
        </Fade>
      </Modal>
      <section className="footer">
      <div className="small_support_sm_right">
            <img src={small_support_sm_right}/>
        </div>
        <Container maxWidth="xl">
          <div className="footer-main">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                <div className="footer-logo">
                  <Link to="/">
                    <img src={logo} />
                  </Link>
                  <div className="protect_dharma">
                    <h2>Step up to Protect Dharma</h2>
                    <p>
                      Join hands with us to partake in multiple Sevas. Embrace
                      the acts of Gau Seva, Annadanam, and extend your support
                      to Temple Construction.
                    </p>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                <div className="quick_link_main">
                  <div className="services">
                    <p>Quick Links</p>
                    <ul>
                      <li>
                        <Link to="/about-us">About Us</Link>
                      </li>
                      <li>
                        <Link to="/privacy-policy">Privacy Policy</Link>
                      </li>
                      <li>
                        <Link to="/terms-conditions">Terms & Conditions</Link>
                      </li>
                      <li>
                        <Link to="/cancellation-policy">
                          Cancellation Policy
                        </Link>
                      </li>
                      <li>
                        <Link to="/faqs">
                          FAQ'S
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                <div className="footer-address">
                  <p><span>Address</span> :</p>
                  <h6>Vaidika Dharma Services Pvt. Ltd.</h6>
                  <h6>Unit No 203, 2nd Floor Suite No.549,</h6>
                <h6>SBR CV Towers, Sector-I,Sy No 64,</h6>  
                  <h6>HUDA Techno Enclave,</h6>
                  <h6>Madhapur, Hyderabad - 500081</h6>
                  <div className="email_phone">
                    <ul>
                        <li>
                            <a href="mailto:support@vedasankalpa.com">
                                <img src={email} />
                                Email :  <span> support@vedasankalpa.com</span>
                            </a>
                        </li>
                        <li>
                            <a href="tel:+91 9256585656">
                                <img src={phone} />
                                Phone : <span>+91 9256585656</span>
                            </a>
                        </li>
                        <li>
                            <p>
                                <img src={timings} />
                                Mon - Thurs : <span> 10:00am - 6:00pm</span>
                            </p>
                        </li>
                        <li>
                            <p>
                                <img src={timings} />
                                Fri - Sat  : <span>10:00am - 2:00pm</span>
                            </p>
                        </li>
                    </ul>
                  </div>
                  <div className="social-icons social-icons_1">
                    <ul>
                        <li>
                        <Link
                            to="https://www.facebook.com/Vedasankalpa"
                            target="_blank"
                        >
                            <img src={Facebook} />
                        </Link>
                        </li>
                        <li>
                        <Link
                            to="https://www.instagram.com/vedasankalpaoriginal?igsh=dnd0eTAwYW8zaGph"
                            target="_blank"
                        >
                            <img src={Instagram} />
                        </Link>
                        </li>
                        <li>
                        <Link
                            to="https://www.linkedin.com/company/veda-sankalpa/?viewAsMember=true"
                            target="_blank"
                        >
                            <img src={Linkedin} />
                        </Link>
                        </li>
                        <li>
                        <Link
                            to="https://www.youtube.com/@Vedasankalpa"
                            target="_blank"
                        >
                            <img src={Youtube} />
                        </Link>
                        </li>
                    </ul>
                    </div>
                </div>
              </Grid>
            
              {/* <Grid item xs={12} sm={12} md={6} lg={2} xl={2}>
                    <div className="services about">
                        <p>About</p>
                        <ul>
                            {/* <li><Button disableRipple disableElevation onClick={handleOpen}>Email Marketing</Button></li>
                            <li><Button disableRipple disableElevation onClick={handleOpen}>Campaigns</Button></li> 
                            <li><Button disableRipple disableElevation onClick={handleOpen}>Our Story</Button></li>
                            <li><Button disableRipple disableElevation onClick={handleOpen}>Benefits</Button></li>
                            <li><Button disableRipple disableElevation onClick={handleOpen}>Team</Button></li>
                            <li><Button disableRipple disableElevation onClick={handleOpen}>Careers</Button></li>
                        </ul>
                       
                    </div>
                    </Grid> */}
            </Grid>
          </div>
          <div className="all_copy-rights-content">
            <div className="copy-rights-content">
              <p>Copyright © 2024. Veda Sankalpa. All rights reserved.</p>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
}
export default Footer;
