import React, { useEffect, useState } from "react";
import "../styles/components/foundation_construct.css";
import { Typography, Button, TextField, Box } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import rightarrow from "../assets/images/icons/white-right-arrow.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useAppContext } from "./Auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import first_top_recent_donors from "../assets/images/icons/donor.png";
import anonymous_donors from "../assets/images/icons/anonymous.png";
import { noauthinstance } from "../utils/api";
import { CallMissedOutgoing } from "@mui/icons-material";

function Foundation_construct({
  donation,
  totalDonation,
  donationAmount,
  setDonationAmount,
  setIsDonationEdited,
  setCountersReset,
  minimumDonation,
  setMinimum,
  minimumAmount,
}) {
  const [Donations, setDonations] = useState([]);
  const [firstCName, setFirstCName] = useState();
  const [LastCName, setLastCName] = useState();
  const [HighCName, setHighCName] = useState();
  const [HighestCustomer, setHighestCustomer] = useState();
  const { slug } = useParams();
  const handleAmountChange = (e) => {
    setMinimum(false);
    console.log(e.target.value);
    let value = e.target.value;
    if (value > 1 && value.startsWith("0")) {
      value = parseInt(e.target.value, 10).toString();
      console.log("value,", value);
    }

    if (value === "" || parseInt(value, 10) >= 0) {
      setDonationAmount(value);
    }

    // const newAmount = Math.round(value);
    // console.log(newAmount);
    setDonationAmount(value);

    setCountersReset((prev) => prev + 1);
    setIsDonationEdited(true);
  };

  useEffect(() => {
    // This runs after `count` has been updated and the component has re-rendered
    console.log("Count has been updated to:", donationAmount);
    console.log(minimumDonation);
  }, [donationAmount, minimumDonation]);

  useEffect(() => {
    const defaultDonation = donation.donation_amounts.find(
      (amount) => amount.is_default
    );

    // Check if a default donation is found
    if (defaultDonation) {
      // Set donation_amount using setDonationAmount
      setDonationAmount(Math.round(defaultDonation.price));
    }
  }, []);

  async function getDonation() {
    try {
      const response = await noauthinstance.get(`donation_orders/${slug}`);
      const response2 = await noauthinstance.get(
        `donation_orders_recent/${slug}`
      );
      const currentDate = new Date();

      if (response2.status === 200) {
        console.log(response2.data);

        const orderDateObj = new Date(
          response2.data.recent_orders[0].order_date
        );
        const differenceMs = currentDate - orderDateObj;

        // Convert milliseconds to days
        const daysAgo = Math.floor(differenceMs / (1000 * 60 * 60 * 24));

        console.log(daysAgo);
        const personalDetails = JSON.parse(
          response2.data.recent_orders[0].personal_details.replace(/'/g, '"')
        );
        setDonations(response2.data.recent_orders);

        const firstDonor =
          response2.data.recent_orders.length > 0
            ? JSON.parse(
                response2.data.recent_orders[0].personal_details.replace(
                  /'/g,
                  '"'
                )
              )
            : {};

        console.log(firstDonor);
        const lastDonor =
          response2.data.recent_orders.length > 0 &&
          response2.data.recent_orders[response2.data.recent_orders.length - 1]
            .is_anyomouns === false
            ? JSON.parse(
                response2.data.recent_orders[
                  response2.data.recent_orders.length - 1
                ].personal_details.replace(/'/g, '"')
              )
            : {};

        const firstAndLastName =
          firstDonor.firstName + " " + firstDonor.lastName;
        setFirstCName(firstAndLastName);
        const lastAndLastName = lastDonor.firstName + " " + lastDonor.lastName;
        setLastCName(lastAndLastName);

        let highestAmount = parseFloat(
          response.data.all_orders[0].original_amount
        );
        let highestPaidCustomer = response.data.all_orders[0];

        response.data.all_orders.forEach((order) => {
          const originalAmount = parseFloat(order.original_amount);
          if (originalAmount > highestAmount) {
            highestAmount = originalAmount;
            highestPaidCustomer = order;
            console.log(order);
          }
        });

        console.log(highestPaidCustomer);

        const highestDonor = JSON.parse(
          highestPaidCustomer.personal_details.replace(/'/g, '"')
        );
        const highestName =
          highestDonor.firstName + " " + highestDonor.lastName;
        setHighCName(highestName);
        setHighestCustomer(highestPaidCustomer);

        console.log(highestName);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getDonation();
  }, []);

  const navigate = useNavigate();
  // console.log(donation);
  // console.log({ donationAmount });
  localStorage.setItem("donationAmount", donationAmount);
  const width =
    totalDonation > donation.target_amount
      ? 100
      : Math.round((100 * totalDonation) / donation.target_amount);

  const total_percentage =
    totalDonation > donation.target_amount
      ? ((totalDonation - donation.target_amount) / donation.target_amount) *
        100
      : (100 * totalDonation) / donation.target_amount;
  const rounded_percentage = Math.round(total_percentage);
  const extra_amount =
    totalDonation > donation.target_amount
      ? Math.round(totalDonation - donation.target_amount)
      : 0;
  const { isLoggedIn, setIsLoggedIn, open, setOpen, phone, setPhone } =
    useAppContext();
  const handlecheck = () => {
    if (
      donationAmount < minimumAmount ||
      donationAmount === "" ||
      donationAmount === null ||
      donationAmount === "undefined" ||
      donationAmount === "0" || donationAmount === 0
    ) {
      setMinimum(true);
      console.log(minimumAmount)
    } else {
      console.log(minimumAmount)
      navigate(`/checkout/${donation.slug}/${donation.id}`);
    }
  };
  const options = { maximumFractionDigits: 2 };
  return (
    <>
      <div className="new_foundation_construct">
        <div className="new_foundation_construct_bg">
          <h3>{donation.name}</h3>
          <ul className="date_event_location">
            <li className="first-child date_event_location_sm">
              <span>Date :</span>
              {new Date(donation.start_date).toLocaleDateString("en-GB", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              })}
            </li>
            <li className="second-child">
              <span>Location :</span>
              {donation.location}
            </li>
            <li className="third-child date_event_location_sm">
              <span>Event :</span>
              {donation.event}
            </li>
          </ul>
          <div className="donate_bg">
            {/* <p>Donate</p> */}
            <div className="product_percentage">
              <h3>
                {totalDonation > donation.target_amount ? (
                  <>
                    Additional <span>{rounded_percentage}%</span> raised
                  </>
                ) : (
                  <>
                    <span>{rounded_percentage}%</span> raised
                  </>
                )}
              </h3>
              <Box className="product_percentage_count">
                <Box
                  sx={{
                    width: `${width}%`,
                  }}
                  className="product_percentage_count_child"
                ></Box>
                <div className="product_percentage_count_child_1"></div>
                <div className="product_percentage_count_child_2"></div>
              </Box>
              <h4>
                ₹{Math.round(totalDonation).toLocaleString("en-IN")} raised out
                of ₹
                {Math.round(donation?.target_amount).toLocaleString("en-IN")}
                {Math.round(totalDonation) > Math.round(donation?.target_amount)
                  ? `(Additional amount of ₹${extra_amount.toLocaleString(
                      "en-IN"
                    )} collected)`
                  : " "}
              </h4>
            </div>
            {donation.donation_expired === false && (
              <>
                <div className="donate_cost_buttons">
                  <ul>
                    {donation.donation_amounts.map((amount) =>
                      amount.is_active === true ? (
                        <li key={amount.id}>
                          <Button
                            className={
                              donationAmount === Math.round(amount.price)
                                ? "active"
                                : ""
                            }
                            onClick={() => {
                              setMinimum(false);
                              setDonationAmount(Math.round(amount.price));
                              setIsDonationEdited(true);
                              setCountersReset((prev) => prev + 1); // Reset counters here
                            }}
                          >
                            ₹{Math.round(amount.price).toLocaleString("en-IN")}
                          </Button>
                        </li>
                      ) : null
                    )}
                  </ul>
                </div>
                <div className="enter_amount outlined_amount">
                  <FormControl fullWidth sx={{ m: 1 }}>
                    <InputLabel htmlFor="outlined-adornment-amount">
                      Amount
                    </InputLabel>
                    <OutlinedInput
                      value={donationAmount}
                      onChange={handleAmountChange}
                      id="outlined-adornment-amount"
                      label="Amount"
                      type="number"
                      inputProps={{ min: "1" }}
                      startAdornment={
                        <InputAdornment position="start">₹</InputAdornment>
                      }
                    />
                  </FormControl>
                  {minimumDonation && (
                    <span className="enter_more_price">Enter ₹{minimumAmount} or more</span>
                  )}
                </div>
              </>
            )}
          </div>
          {donation.donation_expired === false && (
            <div className="donate_now donate_now_sm">
           { rounded_percentage >= 100 ? ( 
            
              <Button className="target_reached_btn">
               Target Reached
              </Button>
            
              ) : (
                <Button
                onClick={() => {
                  // if (!isLoggedIn) {
                  //   setOpen(true);
                  // } else {
                  handlecheck();
                  // }
                }}
              >
                I Donate ₹{" "}
                {Intl.NumberFormat("en-IN", options).format(donationAmount)}
                {/* <img src={rightarrow} /> */}
              </Button>
              )}
              </div>
          )}
          {Donations.length > 0 && (
            <div className="first_top_recent_donors">
              <div className="first_top_recent_donors_main">
                <div className="first_top_recent_donors_list">
                  <div className="anonymous_image">
                    <img
                      src={
                        Donations[0]?.is_anyomouns === false
                          ? first_top_recent_donors
                          : anonymous_donors
                      }
                      alt=""
                    />
                  </div>
                  <div className="anonymous">
                    <h3>
                      {Donations[0]?.is_anyomouns === false
                        ? firstCName
                        : "Anonymous"}
                    </h3>
                    <p>
                      ₹
                      {Math.round(Donations[0]?.original_amount).toLocaleString(
                        "en-IN"
                      )}
                      <span>RECENT DONOR</span>
                    </p>
                  </div>
                </div>
                <div className="first_top_recent_donors_list first_top_recent_donors_list_3">
                  <div className="anonymous_image">
                    <img
                      src={
                        HighestCustomer?.is_anyomouns === false
                          ? first_top_recent_donors
                          : anonymous_donors
                      }
                      alt=""
                    />
                  </div>
                  <div className="anonymous">
                    <h3>
                      {HighestCustomer?.is_anyomouns === false
                        ? HighCName
                        : "Anonymous"}
                    </h3>
                    <p>
                      ₹
                      {Math.round(
                        HighestCustomer?.original_amount
                      ).toLocaleString("en-IN")}{" "}
                      <span>TOP DONOR</span>
                    </p>
                  </div>
                </div>
                {/* <div className="first_top_recent_donors_list first_top_recent_donors_list_2">
                  <div className="anonymous_image">
                    <img
                      src={
                        Donations[Donations.length - 1]?.is_anyomouns === false
                          ? first_top_recent_donors
                          : anonymous_donors
                      }
                      alt=""
                    />
                  </div>
                  <div className="anonymous">
                    <h3>
                      {Donations[Donations.length - 1]?.is_anyomouns === false
                        ? LastCName
                        : "Anonymous"}
                    </h3>
                    <p>
                      ₹
                      {Math.round(
                        Donations[Donations.length - 1]?.original_amount
                      ).toLocaleString("en-IN")}{" "}
                      <span>FIRST DONOR</span>
                    </p>
                  </div>
                </div> */}
              </div>
            </div>
          )}
        </div>
      </div>
      
    </>
  );
}
export default Foundation_construct;
