import React, { useState, useEffect } from "react";
import right_white_arrow from "../assets/images/sepical-offer/right-white-arrow.svg";
import "../styles/components/campaigner_card.css";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  TextField,
  InputAdornment,
} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import "../styles/components/log_in.css";
import close_button from "../assets/images/icons/close_sm.png";
import Grid from "@mui/material/Grid";
import uploadimage from "../assets/images/icons/upload-image.png";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import instance from "../utils/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CloseOutlined } from "@mui/icons-material";
import { useAppContext } from "../components/Auth";

const style = {
  // position: "absolute",
  // top: "50%",
  // left: "50%",
  // transform: "translate(-50%, -50%)",
  // width: 700,
  // borderRadius: 4,
  // p: 4,
};
function Campaigner_card() {
  const [open, setOpenPuja] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const [sendOtp, setSendOtp] = useState(false);
  const [otp, setOtp] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [validate, setValidate] = useState(false);
  const vedatoken = localStorage.getItem("vedatoken");
  const id = localStorage.getItem("vedauserid");
  const { isLoggedIn, setOpen } = useAppContext();
  useEffect(() => {
    if (isLoggedIn === false) {
      setFormData({
        type: "Pooja",
        name: "",
        mobile: "",
        email: "",
        temple_name: "",
        location: "",
        occasion: "",
        text_box: "",
        note: "",
        links: "",
        image: 0,
      });
      setFormData2({
        fullName: "",
        mobileNo: "",
        email: "",
        cause: "",
        noOfStudents: "",
        location: "",
        establishedYear: "",
        requirements: "",
        socialMediaLinks: "",
        note: "",
        ngo: 0,
        image: 0,
      });
      setFormData1({
        name: "",
        mobile: "",
        email: "",
        temple_name: "",
        temple_deity: "",
        renovation_type: "Renovation",
        temple_committee_name: "",
        location: "",
        links: "",
        note: "",
        committee_member_docs: 0,
        image: 0,
      });
      setFormDataVedaPatashala({
        name: "",
        mobile: "",
        email: "",
        patashala_name: "",
        no_of_students: "",
        location: "",
        established_year: "",
        requiuremnts: "",
      });
    }
    fetchProfileData();
  }, [isLoggedIn]);

  const handleOpen = () => {
    if (!isLoggedIn) {
      setOpen(true);
    } else {
      setOpenPuja(true);
    }
  };
  const handleClose = () => {
    setOpenPuja(false);
    setFormData({
      type: "Pooja",
      name: "",
      mobile: formData.mobile,
      email: "",
      temple_name: "",
      location: "",
      occasion: "",
      text_box: "",
      note: "",
      links: "",
      image: 0,
    });
    setFilePreview("");
    setIsHomamSelected(false);
  };
  const handleOpen1 = () => {
    if (!isLoggedIn) {
      setOpen(true);
    } else {
      setOpen1(true);
    }
  };
  const handleClose1 = () => {
    setOpen1(false);
    setFormData2({
      fullName: "",
      mobileNo: formData2.mobileNo,
      email: "",
      cause: "",
      noOfStudents: "",
      location: "",
      establishedYear: "",
      requirements: "",
      socialMediaLinks: "",
      note: "",
      ngo: 0,
      image: 0,
    });
    setFilePreview3("");
    setFilePreview4("");
  };
  const handleOpen2 = () => {
    if (!isLoggedIn) {
      setOpen(true);
    } else {
      setOpen2(true);
    }
  };
  const handleClose2 = () => {
    setOpen2(false);
    setFormData1({
      name: "",
      mobile: formData1.mobile,
      email: "",
      temple_name: "",
      temple_deity: "",
      renovation_type: "Renovation",
      temple_committee_name: "",
      location: "",
      links: "",
      note: "",
      committee_member_docs: 0,
      image: 0,
    });
  };
  const handleOpen3 = () => {
    if (!isLoggedIn) {
      setOpen(true);
    } else {
      setOpen3(true);
    }
  };
  const handleClose3 = () => {
    setOpen3(false);
    setFormDataVedaPatashala({
      name: "",
      mobile: formDataVedaPatashala.mobile,
      email: "",
      patashala_name: "",
      no_of_students: "",
      location: "",
      established_year: "",
      requiuremnts: "",
    });
  };
  const [formData, setFormData] = useState({
    type: "Pooja",
    puja_name: "",
    name: "",
    mobile: "",
    email: "",
    temple_name: "",
    location: "",
    occasion: "",
    text_box: "",
    note: "",
    links: "",
    image: 0,
  });
  const [age, setAge] = useState([null]);
  const [file, setFile] = useState(null);

  useEffect(() => {
    fetchProfileData();
  }, []);

  useEffect(() => {
    if (formData.mobile.length < 10) {
      setSendOtp(false);
    }
  }, [formData.mobile]);

  const fetchProfileData = async () => {
    try {
      const response = await instance.get(`customer/${id}/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${vedatoken}`,
        },
      });
      const mobile = response.data.user.username || "";
      setMobileNo(mobile);
      setFormData({
        ...formData,
        mobile: mobile, // or simply mobile if key and value names are same
      });
      setFormData1({
        ...formData1,
        mobile: mobile, // or simply mobile if key and value names are same
      });
      setFormDataVedaPatashala({
        ...formDataVedaPatashala,
        mobile: mobile,
      });
      setFormData2({
        ...formData2,
        mobileNo: mobile,
      });
      console.log(formData);
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const [filePreview, setFilePreview] = useState("");
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFile(file);
    setFormData({ ...formData, image: file });
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFilePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async () => {
    try {
      let formDataToSend = { ...formData };

      if (formDataToSend.type === "Pooja") {
        if (
          !formDataToSend.name ||
          !formDataToSend.mobile ||
          // !formDataToSend.email ||
          !formDataToSend.temple_name ||
          !formDataToSend.location ||
          !formDataToSend.occasion
        ) {
          toast.error("Please fill in all mandatory fields.");
          return;
        }
        const nameRegex = /^[a-zA-Z]+(?:[\s'-][a-zA-Z]+)*$/;
        if (!nameRegex.test(formDataToSend.name)) {
          toast.error("Please enter a valid name.");
          return;
        }

        const mobileRegex = /^[0-9]{10}$/;
        if (!mobileRegex.test(formDataToSend.mobile)) {
          toast.error("Please enter a valid 10-digit mobile number.");
          return;
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (formDataToSend.email) {
          if (!emailRegex.test(formDataToSend.email)) {
            toast.error("Please enter a valid email address.");
            return;
          }
        }

        const templeNameRegex = /^[a-zA-Z0-9\s'-]+$/;
        if (!templeNameRegex.test(formDataToSend.temple_name)) {
          toast.error("Please enter a valid temple name.");
          return;
        }

        const locationRegex = /^[a-zA-Z0-9\s,./'-]+$/;
        if (!locationRegex.test(formDataToSend.location)) {
          toast.error("Please enter a valid location.");
          return;
        }

        const ocassionRegex = /^[a-zA-Z0-9\s'-.,]+$/;
        if (!ocassionRegex.test(formDataToSend.occasion)) {
          toast.error("Please enter a valid occasion.");
          return;
        }
        const formDataImage = new FormData();
        formDataImage.append("image", file);
        formDataImage.append("image_type", "User");
        const uploadResponse = await instance.post(
          "common/upload/image/",
          formDataImage
        );

        if (uploadResponse.status === 201) {
          const imageId = uploadResponse.data.data.id;
          formDataToSend.image = imageId;
        }
      } else if (formDataToSend.type === "Homa") {
        if (
          !formDataToSend.name ||
          !formDataToSend.mobile ||
          // !formDataToSend.email ||
          !formDataToSend.temple_name ||
          !formDataToSend.location ||
          !formDataToSend.occasion
        ) {
          console.log(!formDataToSend.image);
          toast.error("Please fill in all mandatory fields.");
          return;
        }

        const nameRegex = /^[a-zA-Z]+(?:[\s'-][a-zA-Z]+)*$/;
        if (!nameRegex.test(formDataToSend.name)) {
          toast.error("Please enter a valid name.");
          return;
        }

        const mobileRegex = /^[0-9]{10}$/;
        if (!mobileRegex.test(formDataToSend.mobile)) {
          toast.error("Please enter a valid 10-digit mobile number.");
          return;
        }

        // Validate email format

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (formDataToSend.email) {
          if (!emailRegex.test(formDataToSend.email)) {
            toast.error("Please enter a valid email address.");
            return;
          }
        }

        const templeNameRegex = /^[a-zA-Z0-9\s'-]+$/;
        if (!templeNameRegex.test(formDataToSend.temple_name)) {
          toast.error("Please enter a valid temple name.");
          return;
        }

        const locationRegex = /^[a-zA-Z0-9\s,./'-]+$/;
        if (!locationRegex.test(formDataToSend.location)) {
          toast.error("Please enter a valid location.");
          return;
        }

        const ocassionRegex = /^[a-zA-Z0-9\s'-.,]+$/;
        if (!ocassionRegex.test(formDataToSend.occasion)) {
          toast.error("Please enter a valid occasion.");
          return;
        }

        const formDataImage = new FormData();
        formDataImage.append("image", file);
        formDataImage.append("image_type", "User");
        const uploadResponse = await instance.post(
          "common/upload/image/",
          formDataImage
        );

        if (uploadResponse.status === 201) {
          const imageId = uploadResponse.data.data.id;
          formDataToSend.image = imageId;
        }
      }

      const response = await instance.post(
        "master/campaigner/HomaAndPuja/",
        formDataToSend
      );
      toast.success("Form submitted successfully!");
      handleClose();
      setFormData({
        type: "Pooja",
        name: "",
        mobile: "",
        email: "",
        temple_name: "",
        location: "",
        occasion: "",
        text_box: "",
        note: "",
        links: "",
        image: 0,
        puja_name: "",
      });
      setFilePreview("");
      console.log("Response:", response.data);
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred. Please try again.");
    }
  };

  const [isHomamSelected, setIsHomamSelected] = useState(true);
  const [filePreview1, setFilePreview1] = useState("");
  const [filePreview2, setFilePreview2] = useState("");
  const [formData1, setFormData1] = useState({
    name: "",
    mobile: "",
    email: "",
    temple_name: "",
    temple_deity: "",
    renovation_type: "Renovation",
    temple_committee_name: "",
    location: "",
    links: "",
    note: "",
    committee_member_docs: 0,
    image: 0,
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData1((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleFileChange1 = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFilePreview1(reader.result);
      };
      reader.readAsDataURL(file);
    }
    setFormData1({ ...formData1, committee_member_docs: file });
  };

  const handleFileChange2 = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFilePreview2(reader.result);
      };
      reader.readAsDataURL(file);
    }
    setFormData1({ ...formData1, image: file });
  };

  const handleSubmitTemple = async () => {
    try {
      let formDataToSend1 = { ...formData1 };

      if (
        !formDataToSend1.name ||
        !formDataToSend1.mobile ||
        // !formDataToSend1.email ||
        !formDataToSend1.temple_name ||
        !formDataToSend1.location ||
        !formDataToSend1.temple_committee_name
      ) {
        toast.error("Please fill in all mandatory fields.");
        return;
      }

      const nameRegex = /^[a-zA-Z]+(?:[\s'-][a-zA-Z]+)*$/;
      if (!nameRegex.test(formDataToSend1.name)) {
        toast.error("Please enter a valid name.");
        return;
      }

      const mobileRegex = /^[0-9]{10}$/;
      if (!mobileRegex.test(formDataToSend1.mobile)) {
        toast.error("Please enter a valid 10-digit mobile number.");
        return;
      }

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (formDataToSend1.email) {
        if (!emailRegex.test(formDataToSend1.email)) {
          toast.error("Please enter a valid email address.");
          return;
        }
      }

      const templeNameRegex = /^[a-zA-Z0-9\s'-]+$/;
      if (!templeNameRegex.test(formDataToSend1.temple_name)) {
        toast.error("Please enter a valid temple name.");
        return;
      }

      const locationRegex = /^[a-zA-Z0-9\s,./'-]+$/;
      if (!locationRegex.test(formDataToSend1.location)) {
        toast.error("Please enter a valid location.");
        return;
      }

      const committeeRegex = /^[a-zA-Z0-9\s,./'-]+$/;
      if (!committeeRegex.test(formDataToSend1.temple_committee_name)) {
        toast.error("Please enter a valid committee name.");
        return;
      }

      const formDataImage1 = new FormData();
      formDataImage1.append("image", formData1.committee_member_docs); // Make sure formData1.committee_member_docs is the correct file object
      formDataImage1.append("image_type", "User");

      if (formDataImage1.get("image") == 0) {
        toast.error("Please upload the required images.");
        return;
      }

      const uploadResponse1 = await instance.post(
        "common/upload/image/",
        formDataImage1
      );

      const formDataImage2 = new FormData();
      formDataImage2.append("image", formData1.image); // Make sure formData1.image is the correct file object
      formDataImage2.append("image_type", "User");

      if (formDataImage2.has("image") == 0) {
        toast.error("Please upload the required images.");
        return;
      }

      const uploadResponse2 = await instance.post(
        "common/upload/image/",
        formDataImage2
      );

      if (uploadResponse1.status === 201 && uploadResponse2.status === 201) {
        const committeeMemberDocsId = uploadResponse1.data.data.id;
        const imageId = uploadResponse2.data.data.id;
        const formDataToSend = {
          ...formData1,
          committee_member_docs: committeeMemberDocsId,
          image: imageId,
        };
        const response = await instance.post(
          "master/campaigner/TempleConstruction/",
          formDataToSend
        );
        console.log(response.data);
        toast.success("Form submitted successfully!");

        handleClose2();
        setFormData1({
          name: "",
          mobile: "",
          email: "",
          temple_name: "",
          temple_deity: "",
          renovation_type: "Renovation",
          temple_committee_name: "",
          location: "",
          links: "",
          note: "",
          committee_member_docs: 0,
          image: 0,
        });
        setFilePreview1("");
        setFilePreview2("");

        console.log("Response:", response.data);
      }
    } catch (error) {
      console.error("Error:", error);

      toast.error("An error occurred. Please try again.");
    }
  };

  const [formDataVedaPatashala, setFormDataVedaPatashala] = useState({
    name: "",
    mobile: "",
    email: "",
    patashala_name: "",
    no_of_students: "",
    location: "",
    established_year: "",
    requiuremnts: "",
  });
  const handleInputpatashala = (e) => {
    const { name, value } = e.target;
    setFormDataVedaPatashala((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleSubmitVedaPatashala = async () => {
    try {
      let formDataToSend2 = { ...formDataVedaPatashala };

      if (
        !formDataToSend2.name ||
        !formDataToSend2.mobile ||
        // !formDataToSend2.email ||
        !formDataToSend2.patashala_name ||
        !formDataToSend2.location ||
        !formDataToSend2.no_of_students ||
        !formDataToSend2.established_year
      ) {
        toast.error("Please fill in all mandatory fields.");
        return;
      }

      const nameRegex = /^[a-zA-Z]+(?:[\s'-][a-zA-Z]+)*$/;
      if (!nameRegex.test(formDataToSend2.name)) {
        toast.error("Please enter a valid name.");
        return;
      }

      const mobileRegex = /^[0-9]{10}$/;
      if (!mobileRegex.test(formDataToSend2.mobile)) {
        toast.error("Please enter a valid 10-digit mobile number.");
        return;
      }

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (formDataToSend2.email) {
        if (!emailRegex.test(formDataToSend2.email)) {
          toast.error("Please enter a valid email address.");
          return;
        }
      }

      const templeNameRegex = /^[a-zA-Z0-9\s'-]+$/;
      if (!templeNameRegex.test(formDataToSend2.patashala_name)) {
        toast.error("Please enter a valid patashala name.");
        return;
      }

      const locationRegex = /^[a-zA-Z0-9\s,./'-]+$/;
      if (!locationRegex.test(formDataToSend2.location)) {
        toast.error("Please enter a valid location.");
        return;
      }

      const studentsRegex = /^[0-9]+$/;
      if (!studentsRegex.test(formDataToSend2.no_of_students)) {
        toast.error("Please enter a valid for number of students.");
        return;
      }

      const response = await instance.post(
        "master/campaigner/VedaPatashala/",
        formDataVedaPatashala
      );
      console.log(response.data);
      toast.success("Form submitted successfully!");

      handleClose3();
      setFormDataVedaPatashala({
        name: "",
        mobile: "",
        email: "",
        patashala_name: "",
        no_of_students: "",
        location: "",
        established_year: "",
        requiuremnts: "",
      });
    } catch (error) {
      console.error("Error:", error);

      toast.error("An error occurred. Please try again.");
    }
  };
  const [filePreview3, setFilePreview3] = useState("");
  const [filePreview4, setFilePreview4] = useState("");
  const [formData2, setFormData2] = useState({
    fullName: "",
    mobileNo: "",
    email: "",
    cause: "",
    noOfStudents: "",
    location: "",
    establishedYear: "",
    requirements: "",
    socialMediaLinks: "",
    note: "",
    ngo: 0,
    image: 0,
  });
  const handleInputChange3 = (e) => {
    const { name, value } = e.target;
    setFormData2({
      ...formData2,
      [name]: value,
    });
  };

  const handleFileChange3 = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFilePreview3(reader.result);
      };
      reader.readAsDataURL(file);
    }

    setFormData2({ ...formData2, ngo: file });
  };

  const handleFileChange4 = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFilePreview4(reader.result);
      };
      reader.readAsDataURL(file);
    }

    setFormData2({ ...formData2, image: file });
  };

  const handledonationSubmit = async () => {
    try {
      // Validate form data
      if (
        !formData2.fullName ||
        !formData2.mobileNo ||
        // !formData2.email ||
        !formData2.cause ||
        // !formData2.noOfStudents ||
        !formData2.location ||
        !formData2.establishedYear ||
        !formData2.requirements ||
        // !formData2.socialMediaLinks ||
        // !formData2.note ||
        !formData2.image ||
        !formData2.ngo
      ) {
        toast.error("Please fill in all mandatory fields.");
        return;
      }

      // Validating email
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (formData2.email) {
        if (!emailRegex.test(formData2.email)) {
          toast.error("Please enter a valid email address.");
          return;
        }
      }

      // Validating mobile number
      const mobileRegex = /^[0-9]{10}$/;
      if (!mobileRegex.test(formData2.mobileNo)) {
        toast.error("Please enter a valid 10-digit mobile number.");
        return;
      }

      // Upload NGO document
      const formDataImage1 = new FormData();
      formDataImage1.append("image", formData2.ngo); // Make sure formData2.ngo is the correct file object
      formDataImage1.append("image_type", "User");

      const uploadResponse1 = await instance.post(
        "common/upload/image/",
        formDataImage1
      );

      if (uploadResponse1.status !== 201) {
        toast.error("Failed to upload the NGO document. Please try again.");
        return;
      }
      const ngoId = uploadResponse1.data.data.id;

      // Upload main image
      const formDataImage2 = new FormData();
      formDataImage2.append("image", formData2.image); // Make sure formData2.image is the correct file object
      formDataImage2.append("image_type", "User");

      const uploadResponse2 = await instance.post(
        "common/upload/image/",
        formDataImage2
      );

      if (uploadResponse2.status !== 201) {
        toast.error("Failed to upload the main image. Please try again.");
        return;
      }
      const imageId = uploadResponse2.data.data.id;

      // Create FormData object for main form submission
      const formDataToSend = new FormData();
      formDataToSend.append("name", formData2.fullName);
      formDataToSend.append("mobile", formData2.mobileNo);
      formDataToSend.append("ngo_form", ngoId);
      formDataToSend.append("email", formData2.email);
      formDataToSend.append("cause", formData2.cause);
      formDataToSend.append("no_of_students", formData2.noOfStudents);
      formDataToSend.append("location", formData2.location);
      formDataToSend.append("established_year", formData2.establishedYear);
      formDataToSend.append("requiuremnts", formData2.requirements);
      formDataToSend.append("links", formData2.socialMediaLinks);
      formDataToSend.append("note", formData2.note);
      formDataToSend.append("image", imageId);

      await instance.post("/master/campaigner/DontaionForm/", formDataToSend);

      toast.success("Form submitted successfully");
      handleClose1();

      setFormData2({
        fullName: "",
        mobileNo: "",
        email: "",
        cause: "",
        noOfStudents: "",
        location: "",
        establishedYear: "",
        requirements: "",
        socialMediaLinks: "",
        note: "",
        ngo: 0,
        image: 0,
      });
      setFilePreview3("");
      setFilePreview4("");
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("Error submitting form");
    }
  };

  const handleOtp = () => {
    setSendOtp(!sendOtp);
    SendOtp();
  };

  const SendOtp = async () => {
    try {
      const payload = { mobile_no: formData.mobile };
      const send = await instance.post("customer/generate_otp", payload);
      console.log(send);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="homepuja_bg">
        {/* Homa puja Modal Start*/}
        <Dialog
          className="campaigner-modal"
          fullWidth
          maxWidth={"sm"}
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <DialogTitle className="puja_home_title">
            <Typography variant="h6">Homa & Puja</Typography>
            <Typography variant="body2">
              Purohith/Temple can register here to perform Pujas and Homas
            </Typography>
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseOutlined />
          </IconButton>
          <DialogContent>
            <Box className="home_puja_box" sx={style}>
              <div className="log_in_box_content">
                {/* Homa Puja Start */}
                <div>
                  <div className="home_puja_forms-content">
                    <div className="personal-info">
                      <Grid container spacing={2.5}>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <TextField
                            name="name"
                            label="Full Name *"
                            variant="outlined"
                            value={formData.name}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <TextField
                            name="mobile"
                            label="Mobile No *"
                            variant="outlined"
                            value={formData.mobile}
                            onChange={handleChange}
                            // InputProps={{
                            //   endAdornment: (
                            //     <InputAdornment position="end">
                            //       {formData.mobile != mobileNo &&
                            //         formData.mobile.length === 10 && (
                            //           <Button
                            //             variant="outlined"
                            //             onClick={() => {
                            //               handleOtp();
                            //             }}
                            //           >
                            //             Send Otp
                            //           </Button>
                            //         )}
                            //     </InputAdornment>
                            //   ),
                            // }}
                          />
                        </Grid>
                        {sendOtp && formData.mobile != mobileNo && (
                          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <TextField
                              name="otp"
                              label="Otp"
                              variant="outlined"
                              // value={}
                              // onChange={}
                            />
                          </Grid>
                        )}

                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <TextField
                            name="email"
                            label="Email Id"
                            variant="outlined"
                            value={formData.email}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <TextField
                            name="temple_name"
                            label="Temple Name *"
                            variant="outlined"
                            value={formData.temple_name}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <TextField
                            name="location"
                            label="Location *"
                            variant="outlined"
                            value={formData.location}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <TextField
                            name="occasion"
                            label="Occasion *"
                            variant="outlined"
                            value={formData.occasion}
                            onChange={handleChange}
                          />
                        </Grid>
                        {/* <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <div className="puja_homam_button_form">
                            <div className="InputGroup">
                              <input
                                type="radio"
                                name="type"
                                id="size_1"
                                value="Pooja"
                                checked={!isHomamSelected}
                                onChange={() => {
                                  setIsHomamSelected(false);
                                  setFormData({ ...formData, type: "Pooja" });
                                }}
                              />
                              <label className="puja_button" htmlFor="size_1">
                                Pooja
                              </label>
                              <input
                                type="radio"
                                name="type"
                                id="size_2"
                                value="Homa"
                                checked={isHomamSelected}
                                onChange={() => {
                                  setIsHomamSelected(true);
                                  setFormData({ ...formData, type: "Homa" });
                                }}
                              />
                              <label className="puja_button2" htmlFor="size_2">
                                Homa
                              </label>
                            </div>
                          </div>
                        </Grid> */}
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <TextField
                            name="puja_name"
                            label="Puja or Homa Name *"
                            variant="outlined"
                            value={formData.puja_name}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <TextField
                            name="note"
                            label="Notes"
                            variant="outlined"
                            value={formData.note}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <TextField
                            name="links"
                            label="Social Media Links"
                            variant="outlined"
                            value={formData.links}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <div className="upload_doc">
                            <Button className="btn" component="label">
                              <span className="image">
                                {filePreview ? (
                                  <img
                                    src={filePreview}
                                    alt="Preview"
                                    style={{ maxWidth: "100px" }}
                                  />
                                ) : (
                                  <img src={uploadimage} alt="Upload" />
                                )}
                              </span>
                              <span>
                                <span>
                                  {filePreview && file
                                    ? file.name
                                    : "Previous event images *"}
                                </span>
                              </span>
                              <span className="text_button">
                                Format Jpeg, Png / max size 5Mb
                              </span>
                              <input
                                type="file"
                                style={{ display: "none" }}
                                multiple
                                onChange={handleFileChange}
                              />
                            </Button>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </div>
              </div>
            </Box>
          </DialogContent>
          <DialogActions>
            <div className="puja_homa_submit_button">
              <Button className="btn" onClick={handleSubmit}>
                Submit
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      </div>

      <div className="homepuja_bg">
        {/* Homa puja Modal Start*/}
        <Dialog
          className="campaigner-modal"
          open={open1}
          onClose={handleClose1}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <DialogTitle className="puja_home_title">
            <Typography variant="h6">Donation</Typography>
            <Typography variant="body2">
              An NGO/Trust can register here to have their sevas in the platform
            </Typography>
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose1}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseOutlined />
          </IconButton>
          <DialogContent>
            <Box className="home_puja_box" sx={style}>
              <div className="log_in_box_content">
                {/* Donation  Details Start */}
                <div>
                  <div className="home_puja_forms-content">
                    <div className="personal-info">
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <TextField
                            name="fullName"
                            label="Full Name *"
                            variant="outlined"
                            value={formData2.fullName}
                            onChange={handleInputChange3}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <TextField
                            name="mobileNo"
                            label="Mobile No *"
                            variant="outlined"
                            value={formData2.mobileNo}
                            onChange={handleInputChange3}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <TextField
                            name="email"
                            label="Email Id"
                            variant="outlined"
                            value={formData2.email}
                            onChange={handleInputChange3}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <TextField
                            name="cause"
                            label="Cause *"
                            variant="outlined"
                            value={formData2.cause}
                            onChange={handleInputChange3}
                          />
                        </Grid>
                        {/* <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <TextField
                            name="noOfStudents"
                            label="No of students *"
                            variant="outlined"
                            value={formData2.noOfStudents}
                            onChange={handleInputChange3}
                          />
                        </Grid> */}
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <TextField
                            name="location"
                            label="Location *"
                            variant="outlined"
                            value={formData2.location}
                            onChange={handleInputChange3}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <TextField
                            name="establishedYear"
                            label="Established Year *"
                            variant="outlined"
                            value={formData2.establishedYear}
                            onChange={handleInputChange3}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <TextField
                            name="requirements"
                            label="Requirements *"
                            variant="outlined"
                            value={formData2.requirements}
                            onChange={handleInputChange3}
                          />
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <TextField
                            name="socialMediaLinks"
                            label="Social Media Links"
                            variant="outlined"
                            value={formData2.socialMediaLinks}
                            onChange={handleInputChange3}
                          />
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <TextField
                            name="note"
                            label="Note"
                            variant="outlined"
                            value={formData2.note}
                            onChange={handleInputChange3}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <div className="upload_doc add_commitee_member">
                            <Button
                              disableRipple
                              disableElevation
                              className="btn"
                              component="label"
                            >
                              <span className="image">
                                {filePreview3 ? (
                                  <img
                                    src={filePreview3}
                                    alt="Preview"
                                    style={{ maxWidth: "100px" }}
                                  />
                                ) : (
                                  <img src={uploadimage} alt="Upload" />
                                )}
                              </span>
                              <span>
                                {filePreview3 && formData2.ngo
                                  ? formData2.ngo.name
                                  : "Add NGO Document *"}
                              </span>
                              <span className="text_button">
                                Format Jpeg, Png / max size 5Mb
                              </span>
                              <input
                                type="file"
                                style={{ display: "none" }}
                                multiple
                                onChange={handleFileChange3}
                              />
                            </Button>
                            <Button
                              disableRipple
                              disableElevation
                              className="btn btn1"
                              component="label"
                            >
                              <span className="image">
                                {filePreview4 ? (
                                  <img
                                    src={filePreview4}
                                    alt="Preview"
                                    style={{ maxWidth: "100px" }}
                                  />
                                ) : (
                                  <img src={uploadimage} alt="Upload" />
                                )}
                              </span>
                              <span>
                                {filePreview4 && formData2.image
                                  ? formData2.image.name
                                  : "Select from Computer *"}
                              </span>
                              <span className="text_button">
                                Format Jpeg, Png / max size 5Mb
                              </span>
                              <input
                                type="file"
                                style={{ display: "none" }}
                                multiple
                                onChange={handleFileChange4}
                              />
                            </Button>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </div>
              </div>
            </Box>
          </DialogContent>
          <DialogActions>
            <div className="puja_homa_submit_button">
              <Button className="btn" onClick={handledonationSubmit}>
                Submit
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      </div>

      <div className="homepuja_bg">
        {/* Homa puja Modal Start*/}
        <Dialog
          className="campaigner-modal"
          open={open2}
          onClose={handleClose2}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <DialogTitle className="puja_home_title">
            <Typography variant="h6">Temple Construction / Idols of Deities</Typography>
            <Typography variant="body2">
              Temples which are under construction or under renovation can
              register with us
            </Typography>
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose2}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseOutlined />
          </IconButton>
          <DialogContent>
            <Box className="home_puja_box" sx={style}>
              <div className="log_in_box_content">
                {/*  Temple Construction Start*/}
                <div>
                  <div className="home_puja_forms-content">
                    <div className="personal-info">
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <TextField
                            name="name"
                            value={formData1.name}
                            onChange={handleInputChange}
                            id="outlined-basic"
                            label="Full Name *"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <TextField
                            name="mobile"
                            value={formData1.mobile}
                            onChange={handleInputChange}
                            id="outlined-basic"
                            label="Mobile No *"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <TextField
                            name="email"
                            value={formData1.email}
                            onChange={handleInputChange}
                            id="outlined-basic"
                            label="Email Id"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <TextField
                            name="temple_name"
                            value={formData1.temple_name}
                            onChange={handleInputChange}
                            id="outlined-basic"
                            label="Temple Name *"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <TextField
                            name="temple_deity"
                            value={formData1.temple_deity}
                            onChange={handleInputChange}
                            id="outlined-basic"
                            label="Temple Deity"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <FormControl>
                            <InputLabel id="renovation-type-label">
                              Renovation Type *
                            </InputLabel>
                            <Select
                              labelId="renovation-type-label"
                              id="renovation-type"
                              name="renovation_type"
                              value={formData1.renovation_type}
                              onChange={handleInputChange}
                              label="Renovation Type"
                              variant="outlined"
                            >
                              <MenuItem value="Renovation">Renovation</MenuItem>
                              <MenuItem value="New construction">
                                New construction
                              </MenuItem>
                              <MenuItem value="Under construction">
                                Under construction
                              </MenuItem>
                              <MenuItem value="Idols Vigrahas of Deites">
                              Idols Vigrahas of Deites
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <TextField
                            name="temple_committee_name"
                            value={formData1.temple_committee_name}
                            onChange={handleInputChange}
                            id="outlined-basic"
                            label="Temple Committee Name *"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <TextField
                            name="links"
                            value={formData1.links}
                            onChange={handleInputChange}
                            id="outlined-basic"
                            label="Social Media Links"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <TextField
                            name="location"
                            value={formData1.location}
                            onChange={handleInputChange}
                            id="outlined-basic"
                            label="Location *"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <TextField
                            name="note"
                            value={formData1.note}
                            onChange={handleInputChange}
                            id="outlined-basic"
                            label="Notes"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <div className="upload_doc add_commitee_member">
                            <Button
                              disableRipple
                              disableElevation
                              className="btn"
                              component="label"
                            >
                              <span className="image">
                                {filePreview1 ? (
                                  <img
                                    src={filePreview1}
                                    alt="Preview"
                                    style={{ maxWidth: "100px" }}
                                  />
                                ) : (
                                  <img src={uploadimage} alt="Upload" />
                                )}
                              </span>
                              <span>
                                {filePreview1 && formData1.committee_member_docs
                                  ? formData1.committee_member_docs.name
                                  : "Add Committee Member Document *"}
                              </span>
                              <span className="text_button">
                                Format Jpeg, Png / max size 5Mb
                              </span>
                              <input
                                type="file"
                                style={{ display: "none" }}
                                multiple
                                onChange={handleFileChange1}
                              />
                            </Button>
                            <Button
                              disableRipple
                              disableElevation
                              className="btn btn1"
                              component="label"
                            >
                              <span className="image">
                                {filePreview2 ? (
                                  <img
                                    src={filePreview2}
                                    alt="Preview"
                                    style={{ maxWidth: "100px" }}
                                  />
                                ) : (
                                  <img src={uploadimage} alt="Upload" />
                                )}
                              </span>
                              <span>
                                {filePreview2 && formData1.image
                                  ? formData1.image.name
                                  : "Select from Computer *"}
                              </span>
                              <span className="text_button">
                                Format Jpeg, Png / max size 5Mb
                              </span>
                              <input
                                type="file"
                                style={{ display: "none" }}
                                multiple
                                onChange={handleFileChange2}
                              />
                            </Button>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </div>
                {/* Temple Construction End */}
              </div>
            </Box>
          </DialogContent>
          <DialogActions>
            <div className="puja_homa_submit_button">
              <Button className="btn" onClick={handleSubmitTemple}>
                Submit
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      </div>

      <div className="homepuja_bg">
        <Dialog
          className="campaigner-modal"
          open={open3}
          // onClose={handleClose3}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <DialogTitle className="puja_home_title">
            <Typography variant="h6">Veda Pathshala</Typography>
            <Typography variant="body2">
              Veda Patashalas are onboarded to perform sevas and provide
              extended support for patashala
            </Typography>
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose3}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseOutlined />
          </IconButton>
          <DialogContent>
            <Box className="home_puja_box" sx={style}>
              <div className="log_in_box_content">
                {/* Veda Patashala Start */}
                <div>
                  <div className="home_puja_forms-content">
                    <div className="personal-info">
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <TextField
                            name="name"
                            value={formDataVedaPatashala.name}
                            onChange={handleInputpatashala}
                            id="outlined-basic"
                            label="Full Name *"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <TextField
                            name="mobile"
                            value={formDataVedaPatashala.mobile}
                            onChange={handleInputpatashala}
                            id="outlined-basic"
                            label="Mobile No *"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <TextField
                            name="email"
                            value={formDataVedaPatashala.email}
                            onChange={handleInputpatashala}
                            id="outlined-basic"
                            label="Email Id"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <TextField
                            name="patashala_name"
                            value={formDataVedaPatashala.patashala_name}
                            onChange={handleInputpatashala}
                            id="outlined-basic"
                            label="Registered Pathshala Name *"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <TextField
                            name="no_of_students"
                            value={formDataVedaPatashala.no_of_students}
                            onChange={handleInputpatashala}
                            id="outlined-basic"
                            label="No of Student *"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <TextField
                            name="location"
                            value={formDataVedaPatashala.location}
                            onChange={handleInputpatashala}
                            id="outlined-basic"
                            label="Location *"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <TextField
                            name="established_year"
                            value={formDataVedaPatashala.established_year}
                            onChange={handleInputpatashala}
                            id="outlined-basic"
                            label="Establishment Year *"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <TextField
                            name="requiuremnts"
                            value={formDataVedaPatashala.requiuremnts}
                            onChange={handleInputpatashala}
                            id="outlined-basic"
                            label="Notes"
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </div>
                {/* Veda Patashala End */}
              </div>
            </Box>
          </DialogContent>
          <DialogActions>
            <div className="puja_homa_submit_button">
              <Button className="btn" onClick={handleSubmitVedaPatashala}>
                Submit
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      </div>

      <div className="campaigner_card_main">
        <div className="campaigner_card_content">
          <div className="campaigner_box" onClick={handleOpen}>
            <h3>Homa & Puja</h3>
            {/* <p>
              Thai Pusam Special Rituals in Tiruvannamalai: Subramanya Moo..
            </p> */}
            <div className="donate_btn">
              <Button
                disableRipple
                disableElevation
                onClick={handleOpen}
                className="btn"
              >
                <span>Fill Details</span>
                {/* {/* <img src={right_white_arrow} /> */}
              </Button>
            </div>
          </div>
          <div className="campaigner_box" onClick={handleOpen1}>
            <h3>Donation</h3>
            {/* <p>
              Thai Pusam Special Rituals in Tiruvannamalai: Subramanya Moo..
            </p> */}
            <div className="donate_btn">
              <Button
                disableRipple
                disableElevation
                onClick={handleOpen1}
                className="btn"
              >
                <span>Fill Details</span>
                {/* <img src={right_white_arrow} /> */}
              </Button>
            </div>
          </div>
          <div className="campaigner_box" onClick={handleOpen2}>
            <h3>Temple Construction / Idols of Deities</h3>
            {/* <p>
              Thai Pusam Special Rituals in Tiruvannamalai: Subramanya Moo..
            </p> */}
            <div className="donate_btn">
              <Button
                disableRipple
                disableElevation
                onClick={handleOpen2}
                className="btn"
              >
                <span>Fill Details</span>
                {/* <img src={right_white_arrow} /> */}
              </Button>
            </div>
          </div>
          <div className="campaigner_box" onClick={handleOpen3}>
            <h3>Veda Patashala</h3>
            {/* <p>
              Thai Pusam Special Rituals in Tiruvannamalai: Subramanya Moo..
            </p> */}
            <div className="donate_btn">
              <Button
                disableRipple
                disableElevation
                onClick={handleOpen3}
                className="btn"
              >
                <span>Fill Details</span>
                {/* <img src={right_white_arrow} /> */}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Campaigner_card;
