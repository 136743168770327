import React, { useState, useEffect } from "react";
import "../styles/pages/profile.css";
import edit from "../assets/images/profile/edit.svg";
import "../styles/components/profile_details.css";
import TextField from "@mui/material/TextField";
import { Grid, Button } from "@mui/material";
import { AiFillEdit } from "react-icons/ai";
import axios from "axios";
import instance from "../utils/api";
import { useAppContext } from "./Auth";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Profile_details() {
  const [isEditing, setIsEditing] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  // const [pan,setPan] = useState("");
  const [profilePic, setProfilePic] = useState(null);
  const [profilePicUrl, setProfilePicUrl] = useState("");
  const [profilePictureId, setProfilePictureId] = useState(null);
  const {customerName,setCustomerName ,customerPhone,setCustomerPhone,profilePictureData,setProfilePictureData} = useAppContext();
  const vedatoken = localStorage.getItem("vedatoken");
  const id = localStorage.getItem("vedauserid");

  useEffect(() => {
    fetchProfileData();
  }, []);

  const fetchProfileData = async () => {
    try {
      const response = await instance.get(`customer/${id}/`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const profileData = response.data;
      setFirstName(profileData.user.first_name || "");
      setLastName(profileData.user.last_name || "");
      setEmail(profileData.user.email || "");
      setMobileNo(profileData.user.username || "");
      setCustomerName(`${profileData.user.first_name} ${profileData.user.last_name}`);
      setCustomerPhone(profileData.user.username)
      setProfilePictureData(
        profileData.profile_pic
          ? `${profileData.profile_pic.base_url}${profileData.profile_pic.image_path}`
          : null
      );
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  };

  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };

  const handleProfilePictureChange = async (event) => {
    const file = event.target.files[0];
    setProfilePic(file);
    const reader = new FileReader();
    reader.onloadend = () => {
      setProfilePicUrl(reader.result);
    };
    reader.readAsDataURL(file);

    try {
      const formData = new FormData();
      formData.append("image", file);
      formData.append("image_type", "User");
      const uploadResponse = await instance.post(
        "common/upload/image/",
        formData
      );

      if (uploadResponse.status === 201) {
        const imageId = uploadResponse.data.data.id;
        const imageUrl = `${uploadResponse.data.data.base_url}${uploadResponse.data.data.image_path}`;
        setProfilePictureData(imageUrl);
        setProfilePictureId(imageId);
      } else {
        console.error(
          "Failed to upload profile picture:",
          uploadResponse.statusText
        );
      }
    } catch (error) {
      console.error("Error uploading profile picture:", error);
    }
  };


  const saveProfileData = async () => {
    try {
       
      let updatedProfileData = {
        user: {
          first_name: firstName.trim(),
          last_name: lastName.trim(),
          email: email.trim(),
        },
      };


      if (profilePictureId) {
        updatedProfileData.profile_pic = profilePictureId;
      }
      if (email) {
        const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i;
        if (!emailRegex.test(email)) {
          toast.error("Please enter a valid email address");
          return;
        }
      }

      if (firstName && lastName) {
        const nameRegex = /^[a-zA-Z\s]+$/;
        if (!firstName.match(nameRegex) || !lastName.match(nameRegex)) {
          toast.error("Name should contain only alphabets");
          return;
        }
      }
      const response = await instance.put(
        `customer/${id}/`,
        updatedProfileData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        });

      if (response.status === 200) {
        console.log("Updated profile data:", response.data);
        setIsEditing(false);
            fetchProfileData()
      } else {
        console.error("Failed to update profile data:", response.statusText);
      }
    } catch (error) {
      console.error("Error updating profile data:", error);
    }
  };


  return (
    <>
      <div className="">
        <div className="userprofile_right_bg">
          <div className="userprofile_right_bg_content">
            <div className="personal_info">
              <h3>Personal Information</h3>
            </div>
            <div className="edit_button">
              {isEditing ? (
                <>
                  <Button
                    disableRipple
                    disableElevation
                    onClick={saveProfileData}
                  >
                    Save
                  </Button>
                  <Button
                    disableRipple
                    disableElevation
                    onClick={handleEditClick}
                  >
                    Cancel
                  </Button>
                </>
              ) : (
                <Button
                  disableRipple
                  disableElevation
                  onClick={handleEditClick}
                >
                  Edit
                  <img src={edit} />
                </Button>
              )}
            </div>
          </div>
          <div className="personal-info">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div
                  item
                  alignItems="center"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    position: "relative",
                  }}
                >
                  <label htmlFor="profile-picture">
                    <div
                      style={{ position: "relative" }}
                      className={isEditing ? "img-edit" : undefined}
                    >
                      <img
                        src={
                          profilePictureData
                            ? profilePictureData
                            : profilePicUrl
                            ? profilePicUrl
                            : "https://media.istockphoto.com/id/1337144146/vector/default-avatar-profile-icon-vector.jpg?s=612x612&w=0&k=20&c=BIbFwuv7FxTWvh5S3vB6bkT0Qv8Vn8N5Ffseq84ClGI="
                        }
                        alt="Profile"
                        style={{
                          border: isEditing
                            ? "solid #ff5b00 2px"
                            : "solid #686868 0.5px",
                          borderRadius: "50%",
                          objectFit: "cover",
                          width: "150px",
                          height: "150px",
                        }}
                      />

                      {isEditing && (
                        <div
                          style={{
                            position: "absolute",
                            padding: "3px 5px",
                            right: "8px",
                            bottom: "18px",
                            borderRadius: "50%",
                            backgroundColor: "#ff5b00",
                            color: "white",
                          }}
                        >
                          <AiFillEdit />
                        </div>
                      )}
                    </div>
                  </label>
                  {isEditing && (
                    <input
                      type="file"
                      accept="image/*"
                      id="profile-picture"
                      style={{ display: "none" }}
                      onChange={handleProfilePictureChange}
                    />
                  )}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <TextField
                  id="outlined-basic"
                  label="First Name"
                  variant="outlined"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  disabled={!isEditing}
                  className={!isEditing ? "readonly-text-field" : ""}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <TextField
                  id="outlined-basic"
                  label="Last Name"
                  variant="outlined"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  disabled={!isEditing}
                  className={!isEditing ? "readonly-text-field" : ""}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <TextField
                  id="outlined-basic"
                  label="Email"
                  variant="outlined"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  disabled={!isEditing}
                  className={!isEditing ? "readonly-text-field" : ""}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <TextField
                  id="outlined-basic"
                  label="Mobile No"
                  variant="outlined"
                  value={mobileNo}
                  onChange={(e) => setMobileNo(e.target.value)}
                  disabled={true}
                  className={!isEditing ? "readonly-text-field" : ""}
                />
              </Grid>
              {/* <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <TextField
                  id="outlined-basic"
                  label="Pan No"
                  variant="outlined"
                  value={pan}
                  onChange={(e) => setPan(e.target.value)}
                  disabled={!isEditing}
                  className={!isEditing ? "readonly-text-field" : ""}
                />
              </Grid> */}
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
}

export default Profile_details;
