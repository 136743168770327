import React, { useEffect, useRef, useState } from "react";
import "../styles/pages/details_puja.css";
import Header from "../layouts/Header";
import { Button, Container, Typography, IconButton, Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import Sevacard_details from "../components/Sevacard_details";
import Video from "../components/Video";
import Updates from "../components/Updates";
import Recommended_seva from "../components/Recommended_seva";
import Footer from "../layouts/Footer";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { noauthinstance } from "../utils/api";
import RecommenededDonation from "../components/RecommenededDonation";
import whatsapp from '../assets/images/icons/whatsapp.png'
import GTM from "../utils/gtm";

function Details_puja() {
  let { slug } = useParams();
  const [visibleSection, setVisibleSection] = useState("section1"); //scrollspy
  const [puja, setPuja] = useState(null);
  //scrollspy
  const section1Ref = useRef(null);
  const section2Ref = useRef(null);
  const section3Ref = useRef(null);
  const section4Ref = useRef(null);
  const section45Ref = useRef(null);
  useEffect(() => {
    const handleScroll = () => {
      const sectionRefs = [
        { ref: section1Ref, name: "section1" },
        { ref: section2Ref, name: "section2" },
        { ref: section3Ref, name: "section3" },
        { ref: section4Ref, name: "section4" },
        { ref: section45Ref, name: "section45" },
      ];
      const currentScrollPosition = window.scrollY;
      for (let i = 0; i < sectionRefs.length; i++) {
        const section = sectionRefs[i];
        if (section.ref.current) {
          const sectionTop = section.ref.current.offsetTop - 125; //125
          const sectionBottom = sectionTop + section.ref.current.clientHeight;

          if (
            currentScrollPosition >= sectionTop &&
            currentScrollPosition <= sectionBottom
          ) {
            setVisibleSection(section.name);
          }
        }
      }
    };

    const handleResize = () => {
      // Your code for handling resize, similar to your previous implementation
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    // Initial height measurement
    handleResize();

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleNavClick = (ref) => {
    window.scrollTo({
      top: ref.current.offsetTop - 123, //123
      behavior: "smooth",
    });
    setVisibleSection(ref.current.dataset.section);
  };

  //icons visible functionality
  const [isExpanded, setIsExpanded] = useState(false);

  const handleButtonClick = () => {
    setIsExpanded(!isExpanded);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await noauthinstance.get(`puja/${slug}`);
        setPuja(response.data);
        console.log(response.data.donation_expired);
         try {
           if (response.data.donation_expired === true) {
             // setVisibleSection("section4");
             // handleNavClick(section4Ref);
             setTimeout(() => {
               handleNavClick(section4Ref);
             }, 1000);
           }
         } catch (error) {
           console.log(error);
         }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [slug]);

  useEffect(() => {
    if (slug && puja?.id) {
      new GTM().trackPujaDetails({
        id: puja.id,
        slug,
        name: puja.name,
        event: puja.event,
        org: puja.temple?.name,
      });
    }
  }, [puja, slug]);




  const [showBookNow, setShowBookNow] = useState(false);
  const [showBookSeva, setShowBookSeva] = useState(true);

  useEffect(() => {
    const handleScroll1 = () => {
      const section1 = document.getElementById('sevas-scroll');
      const sectionTop1 = section1?.offsetTop;
      const scrollPosition1 = window.scrollY + window.innerHeight;
      const isMediumScreen = window.innerWidth <= 768; // Adjust the screen width as needed

      if (isMediumScreen) {
      if (scrollPosition1 >= sectionTop1) {
        setShowBookSeva(false);
      } else {
        setShowBookSeva(true);
      }
    }
    };

    window.addEventListener('scroll', handleScroll1);
    return () => window.removeEventListener('scroll', handleScroll1);
  }, []);

  const handleBookSevaClick1 = () => {
    setShowBookNow(true);
  };
  

  return (
    <>
      <Header />
      <section className="new_details_pujas">
      <section className="details_puja">
        <Container maxWidth="xl">
        <Grid container columnSpacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <div className="durga_mata_image">
              {puja && puja.banner_image && (
                <>
                  <img
                    src={`${puja.banner_image.base_url}${puja.banner_image.image_path}`}
                    alt={puja.name}
                  />
                  {/* <div className="durga_mata_image_bg">
                    <img src={durga_mata_bg} />
                  </div> */}
                </>
              )}
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <div className="durga_mata_content">
              {puja && (
                <>
                  <h3>{puja.name}</h3>
                  <div className="date_location_event new_date_location_event">
                    <p className="date">
                      <span>Date :</span>{" "}
                      {puja.start_date
                        ? new Date(puja.start_date).toLocaleDateString(
                            "en-GB",
                            {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                            }
                          )
                        : ""}
                    </p>
                    <p className="location">
                      <span>Cause :</span> {puja.location}
                    </p>
                    <p className="event">
                      <span>Event :</span> {puja.event}
                    </p>
                  </div>
                  {showBookSeva && (
                  <div className="book_seva_button-1">
                    {puja.donation_expired === false && (
                      <a href="#sevas-scroll">
                        <Button 
                        disableRipple 
                        disableElevation 
                        onClick={handleBookSevaClick1}>
                          Book Seva
                        </Button>
                      </a>
                    )}
                  </div>
                    )}
                </>
              )}
            </div>
          </Grid>
        </Grid>
        </Container>
      </section>

      {/* <Sevacard_details/> */}
      <section>
        <div className="champaign-tab">
          <Container maxWidth="xl">
            <nav className="nav">
              <div className="child-nav">
                <Button
                  disableRipple
                  disableElevation
                  className={visibleSection === "section1" ? "active" : ""}
                  onClick={() => handleNavClick(section1Ref)}
                >
                  Sevas
                </Button>
                <Button
                  disableRipple
                  disableElevation
                  className={visibleSection === "section2" ? "active" : ""}
                  onClick={() => handleNavClick(section2Ref)}
                >
                  About Seva
                </Button>
                <Button
                  disableRipple
                  disableElevation
                  className={visibleSection === "section3" ? "active" : ""}
                  onClick={() => handleNavClick(section3Ref)}
                >
                  Video Section
                </Button>
                <Button
                  disableRipple
                  disableElevation
                  className={visibleSection === "section4" ? "active" : ""}
                  onClick={() => handleNavClick(section4Ref)}
                >
                  Updates
                </Button>
                {/* <Button
                  disableRipple
                  disableElevation
                  className={
                    visibleSection === "section45" ? "active" : "d-none-sm"
                  }
                  onClick={() => handleNavClick(section45Ref)}
                >
                  Recommended
                </Button> */}
              </div>
            </nav>

            <div id="sevas-scroll"></div>
            <div ref={section1Ref} data-section="section1" className="section1">
              <Typography variant="h2">Sevas</Typography>
              <div className="seva_card_deatils_">
                {puja && puja.sevas && (
                  <Sevacard_details
                    sevacards={puja.sevas}
                    slug={slug}
                    pujaExpire={puja.donation_expired}
                  />
                )}
              </div>
            </div>
            <div ref={section2Ref} data-section="section2" className="section3">
              {puja && <Updates aboutseva={puja.about} />}
            </div>
            <div ref={section3Ref} data-section="section3" className="section2">
              {puja && puja.promotion && <Video sevavideo={puja.promotion} />}
            </div>
            <div ref={section4Ref} data-section="section3" className="section3">
              <div>
                {puja && puja.updates && puja.updates.length === 0 ? (
                  <div className="update-section">
                    <Typography variant="h4">There are no updates</Typography>
                  </div>
                ) : (
                  puja &&
                  puja.updates.map((update, index) => (
                    <div className="update-section" key={index}>
                      <Typography variant="h4">Updates</Typography>
                      <Typography
                        dangerouslySetInnerHTML={{
                          __html: update.description,
                        }}
                      ></Typography>
                    </div>
                  ))
                )}
              </div>
            </div>
          </Container>
          {/* <Container>
            <Box marginY={6}>
              <div
                ref={section45Ref}
                data-section="section45"
                className="section45 recommnded_donations"
              >
                <Typography variant="h4">Recommended Donations</Typography>
                <RecommenededDonation />
              </div>
            </Box>
          </Container> */}
        </div>
      </section>
      </section>
      <Footer />
      <a href="https://api.whatsapp.com/send?phone=919256585656" target="_blank"  class="btn-whatsapp-pulse btn-whatsapp-pulse-border">
          <img alt="whatsapp" src={whatsapp}/>
        </a>
    </>
  );
}
export default Details_puja;
