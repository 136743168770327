import React, { useEffect, useState } from "react";
import "../styles/pages/profile.css";
import "../styles/pages/check_out.css";
import "../styles/pages/home.css";
import edit from "../assets/images/profile/edit.svg";
import TextField from "@mui/material/TextField";
import { Grid, Button, Stack } from "@mui/material";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Tooltip from "@mui/material/Tooltip";
import SearchIcon from "@mui/icons-material/Search";
import "../styles/components/profile_details.css";
import "../styles/components/order_transaction.css";
import instance from "../utils/api";
import { Link } from "react-router-dom";
import invoiceimg from "../assets/images/icons/invoice.png";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import thanks_for_submitting from "../assets/images/icons/thanks-for-submitting.svg";
import thank_you_logo from "../assets/images/icons/thank-you-logo.svg";
import download_80g from "../assets/images/profile/80g-dowload.svg";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function Order_transaction() {
  const [loading, setLoading] = useState(true);
  const formatDateToIndian = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };
  const [orders, setOrders] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  async function getOrders() {
    try {
      const response = await instance.get("customer/order/my_orders/");
      if (response.status === 200) {
        // console.log(response.data);
        setOrders(response.data);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getOrders();
  }, []);

  const handleSearch = (e) => {
    setSearchInput(e.target.value);
  };

  const filteredOrders = orders.filter((order) => {
    const sevaType = order.order_seva_donation[0]?.seva_type;
    const donation = order.order_seva_donation[0]?.donation;

    if (sevaType) {
      const name = sevaType.seva.name;
      return name.toLowerCase().includes(searchInput.toLowerCase());
    } else if (donation) {
      const name = donation.name;
      return name.toLowerCase().includes(searchInput.toLowerCase());
    } else {
      return false;
    }
  });

  const vedatoken = localStorage.getItem("vedatoken");

  const handleDownload = (id) => {
//https://prod.backend.vedasankalpa.com
    fetch(`https://backend.vedasankalpa.com/master/invoiceView/${id}/`, {
      method: "GET", // or any other HTTP method you need
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${vedatoken}`,
      },
    })
      .then((response) => {
        // Check if response is successful
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        // Return the response blob
        return response.blob();
      })
      .then((blob) => {
        // Create a URL for the blob
        const url = window.URL.createObjectURL(new Blob([blob]));
        // Create a temporary <a> element
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `invoice-${id}.pdf`);
        // Simulate click on the <a> element to trigger download
        link.click();
        // Release the object URL
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("There was a problem with your fetch operation:", error);
      });
  };

  const [form80G, setForm80G] = useState({
    customer_id: "",
    pan_name: "",
    pan_number: "",
    email_id: "",
    address: "",
    order_id: "",
  });

  const customer_id = localStorage.getItem("vedauserid");
  const handleInput80G = (e) => {
    const { name, value } = e.target;
    setForm80G((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (order_id) => {
    try {
      let formDataToSend = {
        ...form80G,
        customer_id: customer_id,
        order_id: order_id,
      };
      console.log(formDataToSend);
      if (
        !formDataToSend.pan_name ||
        !formDataToSend.email_id ||
        !formDataToSend.address
      ) {
        toast.error("Please fill in all mandatory fields.");
        return;
      }

      const nameRegex = /^(?=.*[a-zA-Z])[a-zA-Z\s]+$/;
      if (!formDataToSend.pan_name.match(nameRegex)) {
        toast.error("Name should contain only alphabets");
        return false;
      }
      const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i;
      if (!emailRegex.test(formDataToSend.email_id)) {
        toast.error("Please enter a valid email address");
        return false;
      }
      const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;

       if (formDataToSend.pan_number) {
         if (!panRegex.test(formDataToSend.pan_number)) {
           toast.error("Please enter a valid pan number.");
           return;
         }
       }
      const response = await instance.post(
        "customer/customer_80_form",
        formDataToSend
      );
      console.log(response);
      if (response.status === 200) {
        toast.success("Form submitted successfully");
        setOpen2(true);
      }
      setForm80G({
        customer_id: "",
        pan_name: "",
        pan_number: "",
        email_id: "",
        address: "",
        order_id: "",
      });
    } catch (error) {
      console.log(error);
    }
  };

  // const [open, setOpen] = useState(false);

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };
  // const handleClose = () => {
  //   setOpen(false);
  // };

  const [open2, setOpen2] = useState(false);

  const handleClickOpen_submit_btn = () => {
    setOpen2(true);
  };
  const handleClickClose_submit_btn = (id) => {
    setOpenDialogs((prevState) => ({
      ...prevState,
      [id]: false,
    }));
    handleClose(id);
    setOpen2(false);
  };

  const [openDialogs, setOpenDialogs] = useState({});

  const handleClickOpen = (orderId) => {
    setOpenDialogs((prevState) => ({
      ...prevState,
      [orderId]: true,
    }));
  };

  const handleClose = (orderId) => {
    setOpenDialogs((prevState) => ({
      ...prevState,
      [orderId]: false,
    }));
    setForm80G({
      customer_id: "",
      pan_name: "",
      pan_number: "",
      email_id: "",
      address: "",
      order_id: "",
    });
  };
  const options = { maximumFractionDigits: 2 };
  return (
    <div className="order_transaction">
      <div className="userprofile_right_bg">
        <TextField
          id="search-input"
          label="Search by name"
          value={searchInput}
          onChange={handleSearch}
          InputProps={{
            startAdornment: <SearchIcon />,
          }}
          variant="standard"
        />
        {loading ? <div className="loader"><CircularProgress size={24} /></div> :   filteredOrders.length === 0 ? (
          <div className="no_seva_booked">
            <p>No Seva Booked</p>
          </div>
        ) : (
          <div className="puja_name_search">
            <div className="userprofile_right_bg">
              {filteredOrders.map((order) => (
                <div key={order.id} className="puja_name_search_content">
                  <Stack
                    className="image-container_main"
                    direction={"row"}
                    marginRight={2}
                  >
                    {order.order_seva_donation[0].seva_type &&
                      order.order_seva_donation[0].seva_type.seva && (
                        <div className="image-container">
                          <img
                            src={`${order.order_seva_donation[0].seva_type.seva.image.base_url}${order.order_seva_donation[0].seva_type.seva.image.image_path}`}
                            alt="Seva"
                          />
                        </div>
                      )}
                    {order.order_seva_donation[0].donation &&
                      order.order_seva_donation[0].donation.featured_image && (
                        <div className="image-container">
                          <img
                            src={`${order.order_seva_donation[0].donation.featured_image.base_url}${order.order_seva_donation[0].donation.featured_image.image_path}`}
                            alt="Seva"
                          />
                        </div>
                      )}
                    <div className="puja_name_search_content_list">
                      <h4>
                        {order.order_seva_donation[0].seva_type &&
                        order.order_seva_donation[0].seva_type.seva
                          ? order.order_seva_donation[0].seva_type.seva.name
                          : order.order_seva_donation[0].donation.name}
                      </h4>{" "}
                      <h5>
                        {order.order_seva_donation[0].seva_type
                          ? "Ordered On"
                          : "Donated On"}{" "}
                        {formatDateToIndian(order.order_date)}
                      </h5>
                      <p>
                        ₹{" "}
                        {Intl.NumberFormat("en-IN", options).format(
                          order.final_amount
                        )}{" "}
                      </p>
                    </div>
                  </Stack>
                  <div>
                    <div
                      className={
                        order.payment_status === "Success"
                          ? "success"
                          : order.payment_status === "Failed"
                          ? "failed"
                          : "other"
                      }
                    >
                      {order.payment_status}
                    </div>
                    {order.payment_status === "Success" ? (
                      <div className="invoice-deatils">
                        <Link onClick={() => handleDownload(order.id)}>
                          <span>
                            <img src={invoiceimg} />
                          </span>
                          Download Invoice
                        </Link>
                      </div>
                    ) : null}
                    <div className="apply_eight_g">
                      <React.Fragment>
                        {/* {order.payment_status === "Success" &&
                        order.order_seva_donation[0]?.donation
                          ?.tax_beneficiary === true ? (
                          <Button
                            className="apply_eighty_g_btn"
                            onClick={() => handleClickOpen(order.id)}
                          >
                            <img src={download_80g} alt="" />
                            Apply For 80G
                          </Button>
                        ) : null} */}
                        <BootstrapDialog
                          onClose={() => handleClose(order.id)}
                          aria-labelledby="customized-dialog-title"
                          open={!!openDialogs[order.id]}
                          className="apply_eight_modal apply_eight_g_main_box"
                        >
                          <DialogTitle id="customized-dialog-title">
                            Apply for 80G
                            <IconButton
                              aria-label="close"
                              onClick={() => handleClose(order.id)}
                              sx={{
                                position: "absolute",
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                              }}
                            >
                              <CloseIcon />
                            </IconButton>
                          </DialogTitle>
                          <DialogContent dividers>
                            <div className="personal-info personal_information_form apply_eight_g_main">
                              <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                  <TextField
                                    id="outlined-basic"
                                    label="Email ID *"
                                    variant="outlined"
                                    name="email_id"
                                    value={form80G.email_id}
                                    onChange={handleInput80G}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                  <TextField
                                    id="outlined-basic"
                                    label="Name As per PAN *"
                                    variant="outlined"
                                    name="pan_name"
                                    value={form80G.pan_name}
                                    onChange={handleInput80G}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                  <TextField
                                    id="outlined-basic"
                                    label="PAN Number"
                                    variant="outlined"
                                    name="pan_number"
                                    value={form80G.pan_number}
                                    onChange={handleInput80G}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                  <TextField
                                    id="outlined-basic"
                                    label="Address *"
                                    variant="outlined"
                                    name="address"
                                    value={form80G.address}
                                    onChange={handleInput80G}
                                  />
                                </Grid>
                              </Grid>
                              <div className="eight_g_submit">
                                <React.Fragment>
                                  <Tooltip
                                    title={
                                      !form80G.pan_name ||
                                      !form80G.email_id ||
                                      !form80G.address
                                        ? "Please fill all required personal fields"
                                        : null
                                    }
                                    placement="top"
                                    disableHoverListener={
                                      form80G.pan_name &&
                                      form80G.pan_name.trim() === "" &&
                                      form80G.email_id &&
                                      form80G.email_id.trim() == "" &&
                                      form80G.address &&
                                      form80G.address.trim() == ""
                                    }
                                  >
                                    {" "}
                                    <Button
                                      disableRipple
                                      disableElevation
                                      onClick={() => handleSubmit(order.id)}
                                      disabled={
                                        !form80G.pan_name ||
                                        !form80G.email_id ||
                                        !form80G.address
                                      }
                                    >
                                      Submit
                                    </Button>
                                  </Tooltip>

                                  <BootstrapDialog
                                    onClose={() =>
                                      handleClickClose_submit_btn(order.id)
                                    }
                                    aria-labelledby="customized-dialog-title"
                                    open={open2}
                                    className="eighty_g_modal"
                                  >
                                    <IconButton
                                      aria-label="close"
                                      onClick={() =>
                                        handleClickClose_submit_btn(order.id)
                                      }
                                      sx={{
                                        position: "absolute",
                                        right: 8,
                                        top: 8,
                                        color: (theme) =>
                                          theme.palette.grey[500],
                                      }}
                                    >
                                      <CloseIcon />
                                    </IconButton>
                                    <DialogContent dividers>
                                      <div className="submitting_details">
                                        <div className="submitting_details_content">
                                          <img
                                            className="thank_you_logo"
                                            src={thank_you_logo}
                                            alt=""
                                          />
                                          <p>
                                            Thank you for submitting your 3+
                                            details
                                          </p>
                                          <img
                                            src={thanks_for_submitting}
                                            alt=""
                                          />
                                        </div>
                                        <div className="tax_receipt_title">
                                          <h4>
                                            Your 80G tax receipt will be
                                            e-mailed within 24hrs
                                          </h4>
                                        </div>
                                        <div className="tax_receipt_mail">
                                          <p>
                                            We're making things easier for you!
                                            Instead of receiving separate 80G
                                            certificates for each donation,
                                            you'll receive a consolidated 80G
                                            certificate via email covering all
                                            your donations made through our
                                            platform in the financial year.
                                          </p>
                                          <p>
                                            The corresponding 10BE form that
                                            proves your eligibility for the tax
                                            deduction will also be consolidated.
                                            This way, you won't have to worry
                                            about multiple downloads and can
                                            save time. If you have any questions
                                            or need help, please feel free to
                                            start a chat with us.
                                          </p>
                                        </div>
                                      </div>
                                    </DialogContent>
                                  </BootstrapDialog>
                                </React.Fragment>
                              </div>
                            </div>
                          </DialogContent>
                        </BootstrapDialog>
                      </React.Fragment>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}{" "}
      
      </div>
    </div>
  );
}

export default Order_transaction;
