import React from "react";
import { Typography } from "@mui/material";

function Updates({ aboutseva }) {
  return (
    <>
      <div className="main_updates">
        <div className="update-section">
          <Typography variant="h4">Story</Typography>
          <Typography
            dangerouslySetInnerHTML={{
              __html: aboutseva,
            }}
          ></Typography>
        </div>
      </div>
    </>
  );
}
export default Updates;
