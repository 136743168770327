import React, { useEffect, useRef, useState } from "react"
import "../styles/pages/check_out.css"
import "../styles/pages/seva_checkout.css"
import { Grid, Button, Paper } from "@mui/material"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import { ToastContainer, toast } from "react-toastify";
import TextField from "@mui/material/TextField"
import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import AccordionDetails from "@mui/material/AccordionDetails"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import "react-toastify/dist/ReactToastify.css"
import { instance } from "../utils/api"

export default function AddressEdit({addressToEdit, closeEdit}) {
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState("");
    const [selectedState, setSelectedState] = useState("");
    const [selectedCity, setSelectedCity] = useState("");
    const [fullName, setFullName] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [email, setEmail] = useState("");
    const [addressLine1, setAddressLine1] = useState("");
    const [addressLine2, setAddressLine2] = useState("");
    const [pinCode, setPinCode] = useState("");
    const [whatsappNo, setWhatsappNo] = useState("");
    const [addressType, setAddressType] = useState("");
    const [editingAddressId, setEditingAddressId] = useState(null);

    const handleSaveAddress = async () => {
        try {
        if (
            !fullName ||
            !mobileNumber ||
            !addressLine1 ||
            !pinCode ||
            // !whatsappNo ||
            !addressType
        ) {
            toast.error("Please fill in all mandatory fields.")
            return
        }

        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        if (email && !emailPattern.test(email)) {
            toast.error("Please enter a valid email address.")
            return
        }

        const phonePattern = /^\d{10}$/
        if (!phonePattern.test(mobileNumber)) {
            toast.error("Please enter a valid 10-digit mobile number.")
            return
        }
        if (whatsappNo) {
            if (!phonePattern.test(whatsappNo)) {
            toast.error("Please enter a valid 10-digit WhatsApp number.")
            return
            }
        }

        const dataToSend = {
            full_name: fullName,
            phone_no: mobileNumber,
            whatsup_no: whatsappNo,
            email: email,
            address1: addressLine1,
            address2: addressLine2,
            pincode: pinCode,
            address_type: addressType,
            city: selectedCity,
            state: selectedState,
            country: selectedCountry,
        }

        let response
        if (editingAddressId) {
            response = await instance.patch(
            `customer/address/${editingAddressId}`,
            dataToSend
            )
            console.log(response, "add address")
        } else {
            response = await instance.post("customer/address", dataToSend)
        }

        console.log("Address saved successfully:", response.data)
        toast.success("Address saved successfully.")
        // setShowModalAddress(false)
        setEditingAddressId(null)

        // fetchAddresses()
        // setOpen()
        clearForm()
        // setError("")
        } catch (error) {
        console.error("Error saving/updating address:", error)
        toast.error("Error saving address. Please try again later.")
        }
    }

    const handleEditAddress = async () => {
        // const addressToEdit = addresses.find((address) => address.id === addressId);
        if (addressToEdit) {
          console.log("Address Details", addressToEdit);
          setFullName(addressToEdit.full_name);
          setMobileNumber(addressToEdit.phone_no);
          setWhatsappNo(addressToEdit.whatsup_no);
          setEmail(addressToEdit.email);
          setAddressLine1(addressToEdit.address1);
          setAddressLine2(addressToEdit.address2);
          setPinCode(addressToEdit.pincode);
          setAddressType(addressToEdit.address_type);
          setSelectedCountry(addressToEdit.country);
    
          // const stateExists = states.find(
          //   (state) => state.id === addressToEdit.state.id
          // );
          // if (!stateExists) {
          //   try {
          //     await fetchStates(addressToEdit.country.id);
          //   } catch (error) {
          //     console.error("Error fetching states:", error);
          //   }
          // }
    
          // const cityExists = cities.find(
          //   (city) => city.id === addressToEdit.city.id
          // );
          // if (!cityExists) {
          //   try {
          //     await fetchCities(addressToEdit.state.id);
          //   } catch (error) {
          //     console.error("Error fetching cities:", error);
          //   }
          // }
    
          setSelectedState(addressToEdit.state);
          setSelectedCity(addressToEdit.city);
    
        //   setShowModalAddress(true);
        //   setOpen(true);
        //   setModalMode1("edit");
          setEditingAddressId(addressToEdit.id);
        }
    };

    const clearForm = () => {
        setFullName("");
        // setMobileNumber("");
        setEmail("");
        setAddressLine1("");
        setAddressLine2("");
        setPinCode("");
        setWhatsappNo("");
        setAddressType("");
        setSelectedCountry("");
        setSelectedState("");
        setSelectedCity("");
        closeEdit("")
    };

    const [expanded, setExpanded] = useState(true);

    const handleExpansion = () => {
        setExpanded(!expanded);
    };

    useEffect(()=>{
        handleEditAddress(addressToEdit)
    },[addressToEdit])
  return (
    <Paper elevation={0} className="card-box card-box_2">
      <Accordion className="personal-info" expanded={expanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon onClick={() => handleExpansion()} />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          {/* <div className="personal_info_check_out">
                                      Personal Information
                                    </div> */}
        </AccordionSummary>
        <AccordionDetails>
          <div className="personal_information_form">
            <div className="personal-info">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    id="fullName"
                    label="Full Name *"
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    id="mobileNumber"
                    label="Mobile Number *"
                    value={mobileNumber}
                    onChange={(e) => setMobileNumber(e.target.value)}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    id="email"
                    label="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    id="whatsappNo"
                    label="Alternate No "
                    value={whatsappNo}
                    onChange={(e) => setWhatsappNo(e.target.value)}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={12} xl={12}>
                  <TextField
                    id="addressLine1"
                    label="Address Line 1 *"
                    value={addressLine1}
                    onChange={(e) => setAddressLine1(e.target.value)}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={12} xl={12}>
                  <TextField
                    id="addressLine2"
                    label="Address Line 2"
                    value={addressLine2}
                    onChange={(e) => setAddressLine2(e.target.value)}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <FormControl fullWidth>
                    {/* <InputLabel id="country-label">
                                        Country *
                                      </InputLabel>
                                      <Select
                                        labelId="country-label"
                                        id="country-select"
                                        value={selectedCountry}
                                        onChange={handleChangeCountry}
                                        variant="outlined"
                                        label="Country *"
                                      >
                                        {countries.map((country) => (
                                          <MenuItem
                                            key={country.id}
                                            value={country.id}
                                          >
                                            {country.name}
                                          </MenuItem>
                                        ))}
                                      </Select> */}
                    <TextField
                      id="country"
                      label="Country *"
                      value={selectedCountry}
                      onChange={(e) => setSelectedCountry(e.target.value)}
                      variant="outlined"
                      fullWidth
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <FormControl fullWidth>
                    {/* <InputLabel id="state-label">
                                        State *
                                      </InputLabel>
                                      <Select
                                        labelId="state-label"
                                        id="state-select"
                                        value={selectedState}
                                        onChange={handleChangeState}
                                        variant="outlined"
                                        label="State *"
                                      >
                                        {states.map((state) => (
                                          <MenuItem
                                            key={state.id}
                                            value={state.id}
                                          >
                                            {state.name}
                                          </MenuItem>
                                        ))}
                                      </Select> */}
                    <TextField
                      id="state"
                      label="State *"
                      value={selectedState}
                      onChange={(e) => setSelectedState(e.target.value)}
                      variant="outlined"
                      fullWidth
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <FormControl fullWidth>
                    {/* <InputLabel id="city-label">
                                        City *
                                      </InputLabel>
                                      <Select
                                        labelId="city-label"
                                        id="city-select"
                                        value={selectedCity}
                                        onChange={handleChangeCity}
                                        variant="outlined"
                                        label="City *"
                                      >
                                        {cities.map((city) => (
                                          <MenuItem
                                            key={city.id}
                                            value={city.id}
                                          >
                                            {city.name}
                                          </MenuItem>
                                        ))}
                                      </Select> */}
                    <TextField
                      id="city"
                      label="City *"
                      value={selectedCity}
                      onChange={(e) => setSelectedCity(e.target.value)}
                      variant="outlined"
                      fullWidth
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    id="pinCode"
                    label="Pin Code *"
                    value={pinCode}
                    onChange={(e) => setPinCode(e.target.value)}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <FormControl fullWidth>
                    <InputLabel id="addressType-label">
                      Address Type *
                    </InputLabel>
                    <Select
                      labelId="addressType-label"
                      id="addressType-select"
                      value={addressType}
                      onChange={(e) => setAddressType(e.target.value)}
                      variant="outlined"
                      label="Address Type *"
                    >
                      <MenuItem value="">Select Address Type</MenuItem>
                      <MenuItem value="Home">Home</MenuItem>
                      <MenuItem value="Work">Work</MenuItem>
                      <MenuItem value="Other">Other</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </div>
            <div className="rest_save_button">
              <Button
                disableRipple
                disableElevation
                className="rest"
                onClick={clearForm}
              >
                Discard
              </Button>
              <Button
                disableRipple
                disableElevation
                className="save"
                onClick={() => handleSaveAddress()}
              >
                Save Address
              </Button>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </Paper>
  )
}
