import React, { useRef } from "react";
import { Container, Grid } from "@mui/material";
import fav_icon from "../assets/images/icons/web.png";
import download_doc from "../assets/images/icons/download.png";
import "../styles/pages/payment_cancel.css";
import { toPng } from "html-to-image";
import download from "downloadjs";
import cetificate_donation from "../assets/images/icons/donation_certificate.png";
 
function Thankyou_card() {
  const name = localStorage.getItem("user_name");
  const campaign = localStorage.getItem("organisation_name");
  const noderef = useRef(null);
 
  function downloadImage() {
    const node = noderef.current;
    toPng(node)
      .then((dataUrl) => {
        
        download(dataUrl, "ThankYou.png");
      })
      .catch((error) => {
        console.log("Error", error);
      });
  }
 
  const getFontSizeClass = (length) => {
    if (length <= 15) {
      return "font-28";
    } else if (length <= 20) {
      return "font-20";
    } else if (length <= 25) {
      return "font-16";
    } else if (length <= 30) {
      return "font-14";
    } else if (length <= 35) {
      return "font-12";
    } else {
      return "font-12"; // Default or an additional class for lengths greater than 35
    }
  };
  const truncateName = (name) => {
    if (name.length > 35) {
      return name.substring(0, 35) + "...";
    }
    return name;
  };
 
  const truncatedName = truncateName(name);
  const fontSizeClass = getFontSizeClass(truncatedName.length);
 
  return (
    <div>
    <div className="">
      <div className="payment_1">
        <div className="share_achievement share_lg">
          <ul>
            <li>Share the Achievement</li>
            <li>
              <button onClick={() => downloadImage()}>
                <img src={download_doc} alt="Download PDF" />
              </button>
            </li>
          </ul>
        </div>
        <div className="main_new_payment" ref={noderef}>
          <div className="new-payment">
            <div className="new-payment-content">
              <h3>CERTIFICATE</h3>
              {/* <p>OF APPRECIATION</p> */}
              <img src={cetificate_donation} />
            </div>
            <div className="new-payment-content_2">
              <h4>Proudly presented to</h4>
              <h2 className={fontSizeClass}>{truncatedName}</h2>
            </div>
            <div className="new-payment-content_3">
              <h3>In recogition your donation to help</h3>
              <h4>"{campaign}"</h4>
              {/* <h4>Vedasankalpa</h4> */}
            </div>
            <div className="new-payment-content_4">
              <img src={fav_icon} alt="Icon" />
            </div>
          </div>
        </div>
      </div>
      {/* <div className="share_achievement share_sm">
        <ul>
          <li>Share the Achievement</li>
          <li>
            <button onClick={() => downloadImage("sm-certificate")}>
              <img src={download_doc} alt="Download PDF" />
            </button>
          </li>
        </ul>
      </div> */}
      {/* <div className="sm-certificate" id="sm-certificate">
        <Container>
          <div className="share_achievement">
            <ul>
              <li>Share the Achievement</li>
              <li>
                <button onClick={() => downloadImage("sm-certificate")}>
                  <img src={download_doc} alt="Download PDF" />
                </button>
              </li>
            </ul>
          </div>
          <div className="sm-certificate_step">
            <h3>CERTIFICATE</h3>
            <p>OF APPRECIATION</p>
            <img src={cetificate_donation} />
          </div>
          <div className="sm-certificate_presented">
            <h4>presented to</h4>
            <h2>{name}</h2>
          </div>
          <div className="sm-certificate_campaign">
            <h3>In recogition your donation to help</h3>
            <h4>"{campaign}"</h4>
          </div>
        </Container>
      </div> */}
    </div>
  </div>
  );
}
 
export default Thankyou_card;