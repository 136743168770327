import React, { useEffect, useState } from "react";
import { Button, Container, IconButton } from "@mui/material";
import rightarrow from "../assets/images/icons/white-right-arrow.svg";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { Link, useNavigate } from "react-router-dom";
import "../styles/layouts/header.css";
import Log_in from "../components/Log_in";
import Enter_otp from "../components/Enter_otp";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import profile from "../assets/images/profile/profile_og.svg";
import down from "../assets/images/check_out/mycart/arrow-down-og.svg";
import logout from "../assets/images/icons/logout_og.svg";
import logo from "../assets/images/logo/logo.svg";
import faq from '../assets/images/icons/menu-fasq.png';
import donation from '../assets/images/icons/menu-donation.png';
import exporeall from '../assets/images/icons/menu-exploreall.png';
import Campaigner from '../assets/images/icons/menu-campainger.png';
import puja from '../assets/images/icons/menu-puja.png';
import { useAppContext } from "../components/Auth";
import { CloseOutlined } from "@mui/icons-material";
import { toast } from "react-toastify";
import { jwtDecode } from "jwt-decode";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "4px",
};

function Header() {
  //new
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open1 = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
     const vedatoken = localStorage.getItem("vedatoken");
     const expired = isTokenExpired(vedatoken);

     console.log(expired);
     if (expired === true) {
       toast.error("Session expired. Please Login again.");
       setIsLoggedIn(false);
       localStorage.clear();
       navigate("/");
     }
  };
  const handleClose1 = () => {
    setAnchorEl(null);
  };

  const {isLoggedIn,setIsLoggedIn,open,setOpen,phone,setPhone,customerName,setThankYou,} = useAppContext();
  const navigate = useNavigate();

  const handlelogout = () => {
    localStorage.clear();
    setIsLoggedIn(false);
    handleClose1();
    navigate("/");
    setPhone("");
  };
  // const handleprofile = () => {
  //   navigate("/profile/myprofile");
  // };
  const [otpVerified, setOtpVerified] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
    setPhone("");
  };

  useEffect(() => {
    const vedatoken = localStorage.getItem("vedatoken");
    if (vedatoken) {
      setIsLoggedIn(true);
    }
  }, []);

  const isTokenExpired = (token) => {
    return false;
    //  try {
    //    const decoded = jwtDecode(token);

    //    const exp = decoded.exp;
    //    const now = Math.floor(Date.now() / 1000);

    //    return exp < now;
    //  } catch (e) {
    //    return true;
    //  }
   };


  const handleOtpVerified = () => {
    setOtpVerified(true);
    toast.success("Otp Verified");
    setOpen(false);
    setIsLoggedIn(true);
    setPhone("");
  };

  const handleBack = () => {
    setThankYou(false);
    localStorage.removeItem("Thank_You");
  };
  return (
    <>
      <div className="app_title">
        <Container maxWidth="xl">
          <ul>
            <li className="app_title_logo">
              <Link to="/">
                <img
                  className="logo"
                  src={logo}
                  onClick={() => handleBack()}
                  alt=""
                />
              </Link>
            </li>
           <li className="main_list_items"> 
           <li><Link to="/?slug=donation">Donations</Link></li>
            <li><Link to="/?slug=puja">Puja</Link></li>
            <li> <Link to="/exploreall">Explore All</Link></li>
            <li><Link to="/?slug2=Campaigner">Campaigner</Link></li>
            <li><Link to="/faqs">FAQ's</Link></li>
               
            <li className="app_title_list">
              {isLoggedIn ? (
                <>
                  <Button
                    startIcon={<img src={profile} alt="" />}
                    id="demo-customized-button"
                    aria-controls={open1 ? "demo-customized-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    // variant="contained"
                    disableElevation
                    onClick={handleClick}
                    endIcon={
                      <img
                        src={down}
                        alt=""
                        className={`rotate-icon ${open1 ? "rotate-180" : ""}`}
                      />
                    }
                  >
                    <span classNam="custom_user_name">{customerName}</span>
                  </Button>
                  <Menu
                    sx={{ left: "10px" }}
                    id="demo-customized-menu"
                    anchorEl={anchorEl}
                    open={open1}
                    onClose={handleClose1}
                  >
                    <MenuItem onClick={()=> navigate("/profile/myprofile")}>
                      <img src={profile} alt="profile_menu"  />
                      Profile
                    </MenuItem>
                    <MenuItem onClick={handlelogout} className="lap_design">
                      <img src={logout} alt="logout" />
                      Logout
                    </MenuItem>
                    <MenuItem className="mb_icons">
                    <Link to="/?slug=donation">
                      <img src={donation} alt="donation" />
                      <span>Donations</span>
                      </Link>
                    </MenuItem>
                    <MenuItem className="mb_icons">
                    <Link to="/?slug=puja">
                      <img src={puja} alt="puja" />
                      <span>Puja</span>
                      </Link>
                    </MenuItem>
                    <MenuItem className="mb_icons" onClick={()=> navigate("/exploreall")}>
                      <img src={exporeall} alt="exporeall" />
                      <span>Explore All</span>
                    </MenuItem>
                    <MenuItem className="mb_icons" >
                    <Link to="/?slug2=Campaigner">
                      <img src={Campaigner} alt="Campaigner"/>
                      <span>Campaigner</span>
                      </Link>
                    </MenuItem>
                    <MenuItem className="mb_icons" onClick={()=> navigate("/faqs")}>
                      <img src={faq} alt="faqs" />
                      <span>FAQ's</span>
                    </MenuItem>
                    <MenuItem onClick={handlelogout} className="mob_design">
                      <img src={logout} alt="logout" />
                      Logout
                    </MenuItem> 
                  </Menu>
                </>
              ) : (
                <Button
                  disableRipple
                  disableElevation
                  onClick={() => setOpen("login")}
                  className="btn arrow-btn"
                >
                  <span>Login</span>
                </Button>
              )}
            </li>
            </li>
          </ul>
        </Container>
      </div>
      <section className="login_in">
        <div>
          <Modal
            className="login_modal"
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
              backdrop: {
                timeout: 500,
              },
            }}
          >
            <Fade in={open}>
              <Box sx={style}>
                <IconButton
                  aria-label="close"
                  onClick={handleClose}
                  sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseOutlined />
                </IconButton>
                {open === "otp-page" ? (
                  <Enter_otp
                    username={phone}
                    open={open}
                    setOpen={setOpen}
                    handleOtpVerified={handleOtpVerified}
                  />
                ) : (
                  <Log_in setOpen={setOpen} phone={phone} setPhone={setPhone} />
                )}
              </Box>
            </Fade>
          </Modal>
        </div>
      </section>
    </>
  );
}
export default Header;