import React from 'react'
import '../styles/pages/error.css'
import { Container } from '@mui/material'
import { Link } from 'react-router-dom'

function Error() {
  return (
    <>
    <section className='error_main'>
        <Container>
            <div className='error_content'>
                <h2>404</h2>
                <h3>OOPS! NOTHING WAS FOUND</h3>
                <p>The page you're trying to access may have been removed, renamed, or is temporarily unavailable
                    <Link to='/'>
                    Return to Homepage
                    </Link>
                </p>
            </div>
        </Container>
    </section>
    </>
  )
}

export default Error