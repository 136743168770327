
import React, { createContext, useContext, useState } from 'react';

const AppContext = createContext();


export const AppProvider = ({ children }) => {

const [isLoggedIn, setIsLoggedIn] = useState(false);
const [open, setOpen] = useState(false);
const [phone, setPhone] = useState('');
const[customerName,setCustomerName]=useState('');
const[customerPhone,setCustomerPhone]=useState('');
const [selectedProducts, setSelectedProducts] = useState([]);
const [donationAmount, setDonationAmount] = useState(0);
const [thankYou,setThankYou] = useState(null);
const [thankYouseva, setThankYouseva ] = useState(null);
console.log(selectedProducts)
console.log(donationAmount)
const [profilePictureData, setProfilePictureData] = useState(null);
  return (
    <AppContext.Provider value={{isLoggedIn, setIsLoggedIn, open, setOpen, phone, setPhone,customerName,setCustomerName ,customerPhone,setCustomerPhone,profilePictureData, setProfilePictureData,selectedProducts, setSelectedProducts,donationAmount, setDonationAmount,thankYou,setThankYou,thankYouseva,setThankYouseva}}>
      {children}
    </AppContext.Provider>
  );
};


export const useAppContext = () => useContext(AppContext);
