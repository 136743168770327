import React, { useEffect, useState } from "react";
import { Grid, Button, Typography } from "@mui/material";
import product_image_01 from "../assets/images/details_donation/product-items/image-01.png";
import product_image_02 from "../assets/images/details_donation/product-items/image-02.png";
import "../styles/components/product_items.css";
import { useParams } from "react-router-dom";
import { useAppContext } from "./Auth";

function Product_items({
  products,
  donationAmount,
  isDonationEdited,
  setIsDonationEdited,
  updateDonationAmount,
  countersReset,
}) {
  const [counters, setCounters] = useState(products.map(() => 0));
  const [showDecrement, setShowDecrement] = useState(products.map(() => false));
  const { selectedProducts, setSelectedProducts } = useAppContext();
  useEffect(() => {
    // Reset counters to zero when countersReset changes to true
    if (countersReset) {
      setCounters(products.map(() => 0));
      setShowDecrement(products.map(() => false));
      setSelectedProducts([]);
    }
  }, [countersReset]);
  console.log(products);
  console.log({ counters });

  useEffect(() => {
    console.log({ isDonationEdited });
    if (isDonationEdited === false) {
      updateDonationAmount(calculateTotalPrice());
    } else {
      if (counters.every((item) => item === 0)) {
        setIsDonationEdited(false);
      }
      console.log({ counters, isDonationEdited });
    }
  }, [counters, products]);

  const incrementCounter = (index) => {
    setCounters((prevCounters) => {
      const newCounters = [...prevCounters];
      newCounters[index] += 1;
      return newCounters;
    });
    setShowDecrement((prevShowDecrement) => {
      const newShowDecrement = [...prevShowDecrement];
      newShowDecrement[index] = true;
      return newShowDecrement;
    });

    const selectedProductIndex = Array.isArray(selectedProducts)
      ? selectedProducts.findIndex(
        (item) => item.donation_product === products[index].product_item.id
      )
      : -1;

    if (selectedProductIndex !== -1) {
      // If the product is already selected, update its quantity
      setSelectedProducts((prevSelectedProducts) => {
        const newSelectedProducts = [...prevSelectedProducts];
        newSelectedProducts[selectedProductIndex].quantity += 1;
        return newSelectedProducts;
      });
    } else {
      // If the product is not selected, add it to the selected products
      setSelectedProducts((prevSelectedProducts) => {
        const newSelectedProducts = [...prevSelectedProducts];
        newSelectedProducts.push({
          donation_product: products[index].product_item.id,
          quantity: 1,
        });
        return newSelectedProducts;
      });
    }
  };
  const decrementCounter = (index) => {
    setCounters((prevCounters) => {
      const newCounters = [...prevCounters];
      if (newCounters[index] > 0) {
        newCounters[index] -= 1;
      }
      setShowDecrement((prevShowDecrement) => {
        const newShowDecrement = [...prevShowDecrement];
        newShowDecrement[index] = newCounters[index] > 0; // Show decrement button only if counter is greater than zero
        return newShowDecrement;
      });
      return newCounters;
    });

    // Check if the product is already selected
    const selectedProductIndex = Array.isArray(selectedProducts)
      ? selectedProducts.findIndex(
        (item) => item.donation_product === products[index].product_item.id
      )
      : -1;

    if (selectedProductIndex !== -1) {
      // If the product is already selected
      setSelectedProducts((prevSelectedProducts) => {
        const newSelectedProducts = [...prevSelectedProducts];
        // Decrease the quantity if it's greater than 0
        if (newSelectedProducts[selectedProductIndex].quantity > 0) {
          newSelectedProducts[selectedProductIndex].quantity -= 1;
        }
        // If the quantity becomes 0, remove the product from selectedProducts
        if (newSelectedProducts[selectedProductIndex].quantity === 0) {
          newSelectedProducts.splice(selectedProductIndex, 1);
        }
        return newSelectedProducts;
      });
    }
  };

  const calculateTotalPrice = () => {
    let totalPrice = 0;
    products.forEach((product, index) => {
      totalPrice += product.product_item.price * counters[index];
    });
    return totalPrice;
  };

  console.log(selectedProducts);
  return (
    <>
      <div className="product_item">
        <Grid container spacing={2}>
          {products.length === 0 ? (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              {/* <div className="no-products-message">
                <Typography variant="h6">There are no products</Typography>
              </div> */}
            </Grid>
          ) : (
            products.map((product, index) => (
              <Grid item xs={12} sm={12} md={6} lg={6} xl={4} key={product.id}>
                <div className="product_item_bg product_item_main">
                  <div className="product_item_bg_main_image">
                    {product.product_item && product.product_item.image && (
                      <img
                        src={`${product.product_item.image.base_url}${product.product_item.image.image_path}`}
                      />
                    )}
                  </div>
                  <div className="product_item_bg_main_content">
                    <h3>{product.product_item.name}</h3>
                   

                    <div className="product_product_item_price_mb">
                      <div className="product_product_item_price">
                        <h4>
                          <span>₹</span>
                          {Intl.NumberFormat("en-IN").format(
                            product.product_item.price
                          )}
                        </h4>
                        <div className="ganga_jal_cost_button">
                          {/* <Button onClick={() => decrementCounter(index)}>-</Button>
                    <span>{counters[index]}</span> */}

                          {showDecrement[index] && (
                            <Button className="minus_btn" onClick={() => decrementCounter(index)}>
                              -
                            </Button>
                          )}
                          <span onClick={() => incrementCounter(index)}>
                            {counters[index] === 0 ? "Add" : counters[index]}
                          </span>
                          <Button className="plus_btn" onClick={() => incrementCounter(index)}>+</Button>
                        </div>
                      </div>
                    </div>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: product.product_item.description,
                      }}
                    ></p>
                    <div className="product_product_item_price_lg">
                   <div className="product_product_item_price">
                   <h4>
                    <span>₹</span>
                    {Intl.NumberFormat("en-IN").format(
                          product.product_item.price
                        )}
                    </h4>
                    <div className="ganga_jal_cost_button">
                      {/* <Button onClick={() => decrementCounter(index)}>-</Button>
                    <span>{counters[index]}</span> */}
 
                      {showDecrement[index] && (
                        <Button className="minus_btn" onClick={() => decrementCounter(index)}>
                          -
                        </Button>
                      )}
                      <span onClick={() => incrementCounter(index)}>
                        {counters[index] === 0 ? "Add" : counters[index]}
                      </span>
                      <Button className="plus_btn" onClick={() => incrementCounter(index)}>+</Button>
                    </div>
                   </div>
                   </div>
                  </div>
                </div>
              </Grid>
            ))
          )}

          {/* <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <div className="product_item_bg">
                <img src={product_image_02}/>
                <div className='product_percentage'>
                    <h3><span>25%</span> to complete</h3>
                    <div className='product_percentage_count'>
                        <div className='product_percentage_count_child'></div>
                        <div className='product_percentage_count_child_1'></div>
                        <div className='product_percentage_count_child_2'></div>
                    </div>
                </div>
                <h3>Ganga Jaal</h3>
                <p>Thai Pusam Special Rituals in Tiruvannamalai: Subramanya Moo..</p>
                <h4>₹1,020 </h4>
                <div className='ganga_jal_cost_button'>
                <Button onClick={decrementCounter}>-</Button><span>{counter}</span><Button onClick={incrementCounter}>+</Button>                                                                    </div>
            </div>
            </Grid> */}
        </Grid>
      </div>
    </>
  );
}

export default Product_items;
