import * as React from "react";
import { useState, useEffect } from "react";
import Special_Pujas_Donations from "../assets/images/icons/puja-donation.png";
import rightarrow from "../assets/images/icons/white-right-arrow.svg";
import { Box, Button, Stack } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import {Autoplay, Pagination, Scrollbar } from "swiper/modules";
import "../styles/components/sevacards.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Link, useNavigate } from "react-router-dom";
import { noauthinstance } from "../utils/api";
import right_arrow_btn from '../assets/images/icons/orange-right-arrow.svg'

function Donationcards() {
  const [donations, SetDonations] = useState([]);
  const navigate = useNavigate();

  async function getDonations() {
    try {
      const response = await noauthinstance.get("donationAll/");

      if (response.status === 200) {
        console.log(response.data.results);
        SetDonations(response.data.results);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getDonations();
  }, []);
  const [isReadMore, setIsReadMore] = useState({});

  const toggleReadMore = (donationId) => {
    setIsReadMore((prevState) => ({
      ...prevState,
      [donationId]: !prevState[donationId],
    }));
  };

  return (
    <div className="special-pujas-box">
      <Swiper
        modules={[Autoplay, Pagination, Scrollbar]}
        spaceBetween={50}
        slidesPerView={3}
        loop={true}
        pagination={{ clickable: true }}
        autoplay={{
        delay: 3000,
        // disableOnInteraction: true,
        // reverseDirection: false,

      }}
        // onSwiper={(swiper) => console.log(swiper)}
        // onSlideChange={() => console.log('slide change')}

        breakpoints={{
          320: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          650: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
          992: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          1440: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
        }}
      >
        {donations.map((donation) => (
          <SwiperSlide style={{ height: "auto" }} key={donation.id}>
            <div
              className="pujas_box donation_box"
              onClick={() => navigate(`/donation/${donation.slug}`)}
            >
              {donation.tax_beneficiary && (
                <Box className="tax-benfits-strip">Tax Benefits Available</Box>
              )}

              <Stack sx={{ height: "100%" }} justifyContent={"space-between"}>
                <Box>
                  <div className="puja_featured_image">
                    <img
                      src={`${donation.featured_image.base_url}${donation.featured_image.image_path}`}
                      alt=""
                    />
                  </div>
                  <h3>{donation.short_name}</h3>
                  <p>
                    {donation.short_description.slice(0, 215)}
                    {/* {donation.short_description.length > 75 && <span>...</span>} */}
                  </p>

                  <h4>
                    Org / Trust :<span>{donation.temple.name}</span>
                  </h4>
                </Box>
                <div className="book-now">
                  <Link to={`/donation/${donation.slug}`}>
                    {/* {donation.donation_expired === false ? ( */}
                      <Button className="btn">I Donate</Button>
                    {/* ) : (
                      <Button className="btn">View Updates</Button>
                    )} */}
                  </Link>
                </div>
              </Stack>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="view-all">
              <Link to="/exploreall">
                <Button>View All
                  <img src={right_arrow_btn}/>
                </Button>
              </Link>
            </div>
    </div>
  );
}
export default Donationcards;
