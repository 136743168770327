import React, { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Scrollbar, Pagination } from "swiper/modules";
import { Link } from "react-router-dom";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { noauthinstance } from "../utils/api";
import "../styles/components/special_offer.css";

function Special_offer() {
  const [recommended, setRecommended] = useState([]);

  async function getRecommendations() {
    try {
      const response = await noauthinstance.get("recommended/pujas/donations");

      if (response.status === 200) {
        console.log(response.data);
        setRecommended(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getRecommendations();
  }, []);

  return (
    <div className="recommended_seva">
      <Swiper
        modules={[Scrollbar, Autoplay, Pagination]}
        spaceBetween={50}
        slidesPerView={1}
        pagination={{ clickable: true }}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        loop={true}
        onSwiper={(swiper) => console.log(swiper)}
        onSlideChange={() => console.log("slide change")}
      >
        {recommended.map((item, index) => (
          <SwiperSlide key={index}>
            <div className="bg-banner-main">
              <div
                className="bg-banner_our_special"
                style={{
                  backgroundImage: item.banner_image
                    ? `url(${item.banner_image.base_url}${item.banner_image.image_path})`
                    : "",
                }}
              >
                <div className="special-offer-content">
                  <Box className="special-offer-box-content">
                    <h2>{item.name}</h2>
                    <p>{item.description}</p>
                  </Box>
                  <div className="special-offer-btn">
                    {item.puja ? (
                      <Link to={`/pujas/${item.puja.slug}`}>
                        <Button disableRipple disableElevation className="btn">
                          <span>Book now</span>
                        </Button>
                      </Link>
                    ) : (
                      <Link to={`/donation/${item.donation.slug}`}>
                        <Button disableRipple disableElevation className="btn">
                          <span>I Donate</span>
                        </Button>
                      </Link>
                    )}
                  </div>
                </div>
                {/* <div className="succes-boxing-bg">
         <h2>Our Special offer this Season</h2>
         <p>Lorem Epson is a dummy text to fill the sentencesLorem Epson is a dummy text to fill the sentences</p>
       </div>  */}
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

export default Special_offer;
