import React from "react";
import Header from "../layouts/Header";
import { Container } from "@mui/material";
import '../styles/pages/terms_conditions.css';
import Footer from '../layouts/Footer';
import whatsapp from '../assets/images/icons/whatsapp.png'



function About_us(){
    return(
        <>
        <Header/>
        <section className="terms_conditions">
            <Container maxWidth='xl'>
                <div className="terms_conditions_title">
                    <h2>About Us</h2>
                </div>
            </Container>
        </section>
        <section className="terms_conditions_content_main">
            <Container maxWidth='xl'>
                <div className="terms_conditions_content">
                    <p>Welcome to Veda Sankalpa, a beacon of hope and renewal for the timeless traditions of Sanatana Dharma. Our foundation is built upon the belief that the prosperity of Temples, Dharamshalas, Goshalas, and Veda Pathshalas – the four pillars – forms the cornerstone of upholding the Sanatana Dharma.</p>
                    <p>At Veda Sankalpa, we envision a Bharath where the divine echo of Dharma resounds in every corner, where the spiritual, educational, and compassionate practices of Sanatana Dharma are not just preserved but thrive with renewed vigor and purpose. We aim to ensure that the spiritual flame of our temples burns bright, the wisdom of our scriptures is accessible to all who seek it, and the sanctity of life is honored in our Goshalas and through the noble act of Annadaan.</p>
                </div>
                <div className="terms_conditions_mini_content">
                    <h3>Our Mission</h3>
                    <p><span>Temples</span> : We are committed to supporting temples across Bharath, ensuring they have the means to carry out ‘deep, doop, neivedhyam’ and other sacred rituals. Our efforts also include renovating temples that are in ruins and facilitating the construction of new sanctuaries where communities can gather in worship.</p>
                    <p><span>Dharamshalas</span> : Understanding the importance of hospitality, food, and shelter in our culture, we aim to support Dharamshalas that offer shelter and Annadaan and perform other charitable deeds.</p>
                    <p><span>Goshalas</span> : Recognizing the cow as a symbol of abundance and selfless giving, we support Goshalas throughout Bharath, ensuring the compassionate care and well-being of the cows in their care.</p>
                    <p><span>Veda Pathshalas</span> : We are passionate about preserving and propagating the ancient knowledge of the Vedas and Sanskrit. By supporting Veda Pathshalas, we aspire to inspire a new generation with the wisdom of our scriptures, ensuring the continuity of our spiritual heritage.</p>
                </div>
                <div className="terms_conditions_mini_content">
                    <h3>Join Us</h3>
                    <p>Veda Sankalpa is more than an organization. It is a movement, a collective resolve to reawaken the glory of Sanatana Dharma, making its principles a living reality in the hearts and minds of people across Bharath and beyond. We invite you to join us in this noble cause. Whether through participation, contribution, or spreading awareness, your support is invaluable.</p>
                    <p>Let us walk hand in hand to nurture the soul of Sanatana Dharma for a brighter, compassionate world.</p>
                </div>
            </Container>
        </section>
        <Footer/>
        <a href="https://api.whatsapp.com/send?phone=919256585656" target="_blank"  class="btn-whatsapp-pulse btn-whatsapp-pulse-border">
        <img alt="whatsapp" src={whatsapp}/>
      </a>
        </>
    )
}
export default About_us;