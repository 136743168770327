import React, { useState,useEffect } from "react";
import '../styles/components/log_in.css';
import info from '../assets/images/icons/info.svg'
import { Button, Link } from "@mui/material";
import Back_button from '../assets/images/login-pop-up/orange-left-arrow.svg'
import OtpInput from 'react-otp-input';
import Resend from '../assets/images/login-pop-up/resend.svg'
import { noauthinstance, updateToken } from "../utils/api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GTM from "../utils/gtm";

function Enter_otp({ username, otp,setOpen,open,handleOtpVerified ,setPhone}){
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
// console.log(username)

    const [enteredOtp, setEnteredOtp] = useState('');
    const [seconds, setSeconds] = useState(59);
    const [otpVerified,setOtpVerified] = useState(false);

     const handleResendOtp = async () => {
       try {
         const payload = {
           user: {
             username: username,
             password: username,
           },
         };
         const response = await noauthinstance.post(
           "customer/login/resendOtp",
           payload
         );
         console.log(response);
       } catch (error) {
         console.error("Resend Otp failed:", error);
       }
     };

      useEffect(() => {
        const timer = setInterval(() => {
          if (seconds > 0) {
            setSeconds((prevSeconds) => prevSeconds - 1);
          }
        }, 1000);

        // if(seconds===0 && !otpVerified){
        //     handleResendOtp();
        // }

        // Clear interval when component unmounts or when seconds reach 0
        return () => clearInterval(timer);
      }, [seconds]);

    const handleVerify = async () => {
        try {
            const payload = {
                username: username,
                otp: enteredOtp
            };
            
            const response = await noauthinstance.post('customer/login/verifyOtp', payload);
            
            console.log(response.data);
            if (response.data.message === "Wrong OTP"){
                toast.error("Enter the correct OTP")
            }
              localStorage.setItem("vedatoken", response.data.access);
            localStorage.setItem('vedauserid', response.data.customer.id);
            new GTM().trackUserLogin({username});
            updateToken( response.data.access)
            handleOtpVerified();
            setOtpVerified(true);
            setOpen(false);
        } catch (error) {
            console.error('OTP verification failed:', error);
        }
    };

    const handleWrongNum = () =>{
        setOpen('login')
    }

   

   
    return (
      <div className="">
        <div className="log_in_box_content">
          <div className="welcome_content">
            <h2>Enter OTP</h2>
            <p>
              Sent at <span>+91 {username}</span>
            </p>
            <div className="send-otp-number">
              <OtpInput
                value={enteredOtp}
                onChange={setEnteredOtp}
                numInputs={4}
                renderInput={(props) => <input {...props} />}
              />
            </div>
            <div className="resending-otp">
              {seconds === 0 ? (
                <>
                  <p></p>
                </>
              ) : (
                <>
                  <p>
                    Request OTP in <span>{seconds}s</span>
                  </p>
                </>
              )}

              <Button disableRipple disableElevation onClick={handleWrongNum}>
                Wrong Number?
              </Button>
            </div>
            {seconds === 0 && (
              <div className="otp-resend">
                <p>Didn’t receive the OTP?</p>
                <Button
                  disableRipple
                  disableElevation
                  onClick={handleResendOtp}
                >
                  <img src={Resend} alt="" />
                  <span>Resend</span>
                </Button>
              </div>
            )}

            <div className="login_btn verify_btn">
              <Button onClick={handleVerify} disableRipple disableElevation>
                Verify
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
}
export default Enter_otp;