import React, { useEffect, useState } from "react";
import Header from "../layouts/Header";
import { Container } from "@mui/material";
import "../styles/pages/terms_conditions.css";
import Footer from "../layouts/Footer";
import "../styles/pages/faqs.css";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import { noauthinstance } from "../utils/api";
import whatsapp from '../assets/images/icons/whatsapp.png'

function Faqs() {
  const [faq, setFaq] = useState();
  async function getFaqs() {
    try {
      const response = await noauthinstance.get(`faqList/`);
      console.log(response.data);
      setFaq(response.data.reverse());
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getFaqs();
  }, []);

  return (
    <>
      <Header />
      <section className="terms_conditions">
        <Container maxWidth="xl">
          <div className="terms_conditions_title">
            <h2>FAQ's</h2>
          </div>
        </Container>
      </section>
      <section className="faqs">
        <Container maxWidth="xl">
          {/* <div className="what_is_vedasankalpa">
            <h3>What is Veda Sankalpa ?</h3>
            <p>
              Welcome to Veda Sankalpa, a beacon of hope and renewal for the
              timeless traditions of Sanatana Dharma. Our foundation is built
              upon the belief that the prosperity of Temples, Dharamshalas,
              Goshalas, and Veda Pathshalas – the four pillars – forms the
              cornerstone of upholding the Sanatana Dharma. At Veda Sankalpa, we
              envision a Bharath where the divine echo of Dharma resounds in
              every corner, where the spiritual, educational, and compassionate
              practices of Sanatana Dharma are not just preserved but thrive
              with renewed vigor and purpose. We aim to ensure that the
              spiritual flame of our temples burns bright, the wisdom of our
              scriptures is accessible to all who seek it, and the sanctity of
              life is honored in our Goshalas and through the noble act of
              Annadaan.
            </p>
          </div> */}
          <div className="main_faqs">
            {faq?.map(
              (item, index) =>
                item.is_active && (
                  <Accordion
                    key={item.id}
                    defaultExpanded={index === 0 ? true : false}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`panel${item.id}-content`}
                      id={`panel${item.id}-header`}
                    >
                      {item.question}
                    </AccordionSummary>
                    <AccordionDetails>
                      <p>
                        {item.answer.split("\n").map((line, index) => (
                          <span key={index}>
                            {line}
                            <br />
                          </span>
                        ))}
                      </p>
                    </AccordionDetails>
                  </Accordion>
                )
            )}
          </div>
        </Container>
      </section>
      <Footer />
      <a href="https://api.whatsapp.com/send?phone=919256585656" target="_blank"  class="btn-whatsapp-pulse btn-whatsapp-pulse-border">
          <img alt="whatsapp" src={whatsapp}/>
      </a>
    </>
  );
}
export default Faqs;
